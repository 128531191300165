import React from "react";
import Button from "../../shared-ui/button";
import clientStyles from "../client.module.scss";
import logoPng from "../../../assets/logo.png";

function LogoutSuccessPage() {
  const params = new URLSearchParams(window.location.search);
  const loginUri = params.get("login_uri");

  return (
    <div className={clientStyles["data-wrapper"]}>
      <img src={logoPng} alt="logo" className={clientStyles.logo} />
      <h1>You have been successfuly logged out.</h1>
      <Button
        colors="primary"
        href={loginUri}
        testId="login-button"
        className={clientStyles["login-button"]}
      >
        Log In
      </Button>
    </div>
  );
}

export default LogoutSuccessPage;
