interface IEnum {
  [K: string]: number | string;
}

class EnumUtils {
  keys(enumObject: IEnum) {
    return Object.keys(enumObject).filter((k) => isNaN(Number(k)));
  }
  values(enumObject: IEnum) {
    return Object.values(enumObject).filter(
      (v) => !isNaN(Number(v)) || !enumObject[v]
    );
  }
}

export const enumUtils = new EnumUtils();
