import {
  IStorageUsageBytesOvertimeRequest,
  ITimeChartResponse,
  IStorageUsagePerUserRequest,
  IStorageUsagePerUserResponse,
  IStorageUsagePerStorageNodeRequest,
  IActivitiesChartRecord,
  IUsersOverTimeChartRecord,
  ITimeFrameRequest,
  IDevicesOverTimeChartRecord,
  ISecureFilesCountResponse,
  IChartResponse,
  IMostActiveUsersChartRecord,
  IPolicyStorageUsage,
  StorageSpaceStatus,
  IStorageTypeSpeedPerformanceRequest,
  IChartRecord,
} from "../entities/dashboard";
import { IStorageUsagePerProfileRequest, IStorageUsagePerProfileResponse } from "../entities/policy";
import { IStorageNodePerformance, IStorageNodePerformanceSearchParams } from "../entities/storage-node-performance";
import { IStorageNodeUsage, IStorageNodeUsageSearchParams } from "../entities/storage-node-usage";
import { DataService } from "./data-service";

class DashboardService extends DataService {
  constructor() {
    super("dashboard");
  }

  dataTransmission(params: IStorageUsageBytesOvertimeRequest) {
    return this.api.get<ITimeChartResponse>(
      "dataTransmission",
      params
    );
  }

  storageUsagePerUser(params: IStorageUsagePerUserRequest) {
    return this.api.get<IStorageUsagePerUserResponse>(
      "storageUsagePerUser",
      params
    );
  }

  storageOverTime(params: IStorageUsagePerStorageNodeRequest) {
    return this.api.get<ITimeChartResponse>(
      "storageOverTime",
      params
    );
  }
  activitiesOverTime(params: ITimeFrameRequest) {
    return this.api.get<IActivitiesChartRecord[]>(
      "activitiesOverTime",
      params
    );
  }

  usersOverTime(params: ITimeFrameRequest) {
    return this.api.get<IUsersOverTimeChartRecord[]>("usersOverTime", params);
  }
  devicesOverTime(params: ITimeFrameRequest) {
    return this.api.get<IDevicesOverTimeChartRecord[]>(
      "devicesOverTime",
      params
    );
  }
  secureFilesCount() {
    return this.api.get<ISecureFilesCountResponse>("secureFilesCount");
  }
  getActiveUsers(
    params: ITimeFrameRequest
  ): Promise<IChartResponse<IMostActiveUsersChartRecord>> {
    return this.api.get<IChartResponse<IMostActiveUsersChartRecord>>(
      "activeUsers",
      params
    );
  }
  userStorageStatus() {
    return this.api.get<IPolicyStorageUsage[]>("userStorageStatus");
  }
  storageSpaceStatus() {
    return this.api.get<StorageSpaceStatus>("storageSpaceStatus");
  }
  storagePerformance(params: IStorageTypeSpeedPerformanceRequest) {
    return this.api.get<IChartRecord[]>("storagePerformance", params);
  }
  storageUsagePerProfile(
    request: IStorageUsagePerProfileRequest
  ): Promise<IStorageUsagePerProfileResponse> {
    return this.api.get<IStorageUsagePerProfileResponse>(
      "storageUsagePerProfile",
      request
    );
  }
  totalStorageUsage(searchParams: IStorageNodeUsageSearchParams): Promise<IStorageNodeUsage[]> {
    return this.api.get<IStorageNodeUsage[]>("totalStorageUsage", searchParams);
  }
  storageSpeed(searchParams: IStorageNodePerformanceSearchParams): Promise<IStorageNodePerformance[]> {
    return this.api.get<IStorageNodePerformance[]>("storageSpeed", searchParams);
  }
}

export const dashboardService = new DashboardService();
