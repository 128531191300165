import React, { useEffect } from "react";
import Button from "../shared-ui/button";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useStyles } from "./loginStyles";
import { connect } from "react-redux";
import { IAppState } from "../../redux/reducers";
import logoPng from "../../assets/logo.png";
import { CONFIG } from "../../config/config";

interface ILoginProps extends ReturnType<typeof mapStateToProps> {}

function Login(props: ILoginProps & RouteComponentProps) {
  const classes = useStyles();

  useEffect(() => {
    if (props.user) {
      props.history.replace("/");
    }
    // supress eslint warning because effect should be triggered when prop.token changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user]);

  return (
    <div className={classes.container}>
      <div className={classes.loginwrap}>
        <CssBaseline />
        <div className={classes.paper}>
          <div className={classes.logoimg}>
            <img
              id="logo"
              height="114px"
              width="360px"
              src={logoPng}
              alt="logo"
            />
          </div>
          {props.loginFailed ? (
            <>
              <div className={classes.loginFailedTitle}>
                Authentication failure
              </div>
              <div className={classes.loginFailedInfo}>
                Please contact your administrator for more information.
              </div>
            </>
          ) : (
            <>
              <Typography
                component="h1"
                variant="h5"
                className={classes.title}
                id="welcome"
              >
                Welcome! Login to the Myota Console
              </Typography>
              <Button
                colors="primary"
                href={CONFIG.cognitoLogin}
                className={classes.loginButton}
                testId="login-button"
              >
                Login
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state: IAppState) => ({
  user: state.users.currentUser,
  loginFailed: state.account.loginFailed,
});

export default connect(mapStateToProps)(withRouter(Login));
