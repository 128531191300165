enum LocalStorageKey {
  PostLoginRedirect = "postLoginRedirect",
  UserActiveTime = "userActiveTime",
}

class LocalStorageUtils {
  setPostLoginRedirect(value: string) {
    localStorage.setItem(LocalStorageKey.PostLoginRedirect, value);
  }
  getPostLoginRedirect(): string {
    const result = localStorage.getItem(LocalStorageKey.PostLoginRedirect);
    localStorage.removeItem(LocalStorageKey.PostLoginRedirect);
    return result;
  }
}
const localStorageUtils = new LocalStorageUtils();

export default localStorageUtils;
