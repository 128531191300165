import { DataService } from "./data-service";
import {
  IUser,
  LogoutType,
  BlockUserStatus,
  IUsersRequestParams,
  IUserFilterParams,
  IUserShort,
  IUserDetails,
  ICurrentUser,
  IUserFileStats,
} from "../entities/user";
import { IPage } from "../entities/page";
import { IPolicyShort } from "../entities/policy";
import { IEntityService } from "./entity-service";
import { IUserDevice } from "../entities/device";

class UserService extends DataService implements IEntityService<IUser, IUsersRequestParams> {
  constructor() {
    super("user");
  }

  getPage(searchParams?: IUsersRequestParams) {
    return this.api.get<IPage<IUser>>("", searchParams);
  }
  getUserDevices(uid: string, excludeDeviceIds?: number[]): Promise<IUserDevice[]> {
    return this.api.get<IUserDevice[]>(`${uid}/devices`, { excludeDeviceIds });
  }
  getPolicies() {
    return this.api.get<IPolicyShort[]>("policies");
  }
  getUserDetail(uid: string) {
    return this.api.get<IUserDetails>(uid);
  }
  getFileStats(uid: string) {
    return this.api.get<IUserFileStats>(`${uid}/fileStats`);
  }
  logout() {
    return this.api.post<IUser>("logout", { type: LogoutType.Logout });
  }
  blockUser(userId: number) {
    return this.api.post<IUser>("block", { UserID: userId });
  }
  blockUserList(ids: number[], status?: BlockUserStatus) {
    return this.api.post<{ msg: string }>("block/users", {
      ids,
      status,
    });
  }
  expire(ids: number[]) {
    return this.api.post("expire", { ids });
  }
  selectAll(searchParams?: IUserFilterParams) {
    return this.api.get<IUserShort[]>("compact", searchParams);
  }
  getCurrentUser() {
    return this.api.get<ICurrentUser>("getCurrent");
  }
}

export const userService = new UserService();
