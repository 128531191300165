import React, { ReactNode } from "react";
import inputStyles from "../../shared-ui/styles/input.module.scss";
import SpaceSelector from "./space-selector";
import TooltipQuestion from "../../shared-ui/tooltip-question";
import clsx from "clsx";
import SidePanelSection from "../../shared-ui/side-panel-section/side-panel-section";

const TOOLTIP = "You can configure storage space up to 10 TB per user";

interface IAllottedSpaceProps {
  autoFocus?: boolean;
  allottedSpace: number;
  disabled?: boolean;
  className?: string;
  error?: ReactNode;

  handleAllottedSpace: (value: number) => void;
}

const AllottedSpace = (props: IAllottedSpaceProps) => {
  return (
    <SidePanelSection
      title="Allotted Space"
      className={props.className}
      testId="alloted-space"
    >
      <div
        data-testid="allotted-space-input-label"
        style={{ marginBottom: "8px" }}
      >
        Storage space allotted for each user
        <TooltipQuestion>{TOOLTIP}</TooltipQuestion>
      </div>
      <SpaceSelector
        testId="alloted-space-selector"
        autoFocus={props.autoFocus}
        className={clsx({
          [inputStyles.error]: !!props.error,
        })}
        value={props.allottedSpace}
        hideMb
        disabled={props.disabled}
        tooltip={TOOLTIP}
        onChange={props.handleAllottedSpace}
      />
      {!!props.error && <div className="error-text">{props.error}</div>}
    </SidePanelSection>
  );
};

export default AllottedSpace;
