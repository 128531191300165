import { ApiException } from "../../api/services/api-service";
import { CHANGES_CONFLICT, SOMETHING_WRONG } from "../../constants/messages";
import { IAlertDialogProps } from "../../components/shared-ui/alertDialog";
import { Action } from "redux";
import { usersActions } from "./users";
import { HttpCode } from "../../api/http-code";
import { store } from "../..";

export type PickActions<
  T extends { [K in keyof T]: (...args: any) => Action }
> = ReturnType<T[keyof T]>;

export enum CommonActionType {
  OpenAlertDialog = "COMMON_OPEN_ALERT_DIALOG",
  CloseAlertDialog = "COMMON_CLOSE_ALERT_DIALOG",
  SetConfirmationFlag = "COMMON_SET_CONFIRMATION_FLAG",
  DialogActionSucess = "COMMON_DIALOG_ACTION_SUCCESS",
  StartLoading = "COMMON_START_LOADING",
  StopLoding = "COMMON_STOP_LOADING",
  SnackbarFlag = "COMMON_SNACKBAR_FLAG",
  SetLeftPanelOpen = "COMMON_SET_LEFT_PANEL_OPEN",
  SetRightNavOpen = "COMMON_SET_RIGHT_NAV_OPEN",
  OpenChangesConflictInfo = "COMMON_OPEN_CHANGES_CONFLICT_INFO",
  CloseChangesConflictInfo = "COMMON_CLOSE_CHANGES_CONFLICT_INFO",
  OpenDeleteConfirmation = "OPEN_DELETE_CONFIRMATION",
  CloseDeleteConfirmation = "CLOSE_DELETE_CONFIRMATION",
}

class CommonActions {
  openAlert(payload: IAlertDialogProps) {
    return {
      type: CommonActionType.OpenAlertDialog as typeof CommonActionType.OpenAlertDialog,
      payload: { ...payload },
    };
  }
  closeAlert() {
    return {
      type: CommonActionType.CloseAlertDialog as typeof CommonActionType.CloseAlertDialog,
    };
  }
  setConfirmationFlag(flag, confirmationPayload?) {
    return {
      type: CommonActionType.SetConfirmationFlag as typeof CommonActionType.SetConfirmationFlag,
      flag,
      confirmationPayload,
    };
  }
  dialogSuccess(flag: boolean, description: string, newTitle?: string) {
    return {
      type: CommonActionType.DialogActionSucess as typeof CommonActionType.DialogActionSucess,
      flag,
      description,
      newTitle,
    };
  }
  openChangesConflictInfo(data: string) {
    return {
      type: CommonActionType.OpenChangesConflictInfo as typeof CommonActionType.OpenChangesConflictInfo,
      data,
    };
  }

  closeChangesConflictInfo() {
    return {
      type: CommonActionType.CloseChangesConflictInfo as typeof CommonActionType.CloseChangesConflictInfo,
    };
  }
  startLoading() {
    return {
      type: CommonActionType.StartLoading as typeof CommonActionType.StartLoading,
    };
  }
  stopLoading() {
    return {
      type: CommonActionType.StopLoding as typeof CommonActionType.StopLoding,
    };
  }
  showSnackBar(flag: boolean, message?: string) {
    return {
      type: CommonActionType.SnackbarFlag as typeof CommonActionType.SnackbarFlag,
      flag,
      message,
    };
  }
  setLeftPanelOpen(flag: boolean) {
    return {
      type: CommonActionType.SetLeftPanelOpen as typeof CommonActionType.SetLeftPanelOpen,
      flag,
    };
  }
  setRightNavOpen(flag: boolean) {
    return {
      type: CommonActionType.SetRightNavOpen as typeof CommonActionType.SetRightNavOpen,
      flag,
    };
  }
  openDeleteConfirmation(data: any[]) {
    return {
      type: CommonActionType.OpenDeleteConfirmation as typeof CommonActionType.OpenDeleteConfirmation,
      data,
    };
  }

  closeDeleteConfirmation() {
    return {
      type: CommonActionType.CloseDeleteConfirmation as typeof CommonActionType.CloseDeleteConfirmation,
    };
  }
}

export const commonActions = new CommonActions();

export type CommonAction = PickActions<CommonActions>;

export function handleApi<TData>(promise: Promise<TData>, loading?: boolean, onReject?: (reason: any) => void, onFinally?: () => void): Promise<TData> {
  if (loading) {
    store.dispatch(commonActions.startLoading());
  }
  return new Promise<TData>((resolve) => {
    promise.finally(() => {
      if (loading) {
        store.dispatch(commonActions.stopLoading());
      }
      onFinally && onFinally();
    }).then(resolve).catch(onReject || catchException());
  });
}

export function catchException(changesConflictError?: ()=> void) {
  return (exception: ApiException | Error | string) => {
    if (!exception) {
      return;
    }

    if (exception instanceof ApiException) {
      try {
        switch (exception.status) {
          case HttpCode.UNAUTHORIZED_ERROR:
          case HttpCode.REQUEST_TIMEOUT_ERROR:
            store.dispatch(usersActions.logoutCurrentUserAction());
            break;
          default:
            if (exception.status === HttpCode.PRECONDITION_FAILED_ERROR && !(exception.data && exception.data.subCode)) {
              if (changesConflictError) {
                changesConflictError();
              }
              else {
                store.dispatch(commonActions.openChangesConflictInfo(CHANGES_CONFLICT));
              }
            }
            else {
              store.dispatch(commonActions.showSnackBar(true, exception.message ? `Error: ${exception.message}` : SOMETHING_WRONG));
            }
            break;
        }
      } catch {}
    } else {
      store.dispatch(commonActions.showSnackBar(true, SOMETHING_WRONG));
      console.error(exception);
    }
  };
}
