import React, { useState, useEffect, useCallback } from "react";
import styles from "../manage-users.module.scss";
import Table, {
  ICheckboxColumnDefinition,
  IColumnDefinition,
  TableSelected,
} from "../../../shared-ui/table/table";
import {
  IUser,
  IUsersRequestParams,
  UserSortField,
} from "../../../../api/entities/user";
import TooltipQuestion from "../../../shared-ui/tooltip-question";
import { userService } from "../../../../api/services/user-service";
import { USERS_DEFAULT_STATE } from "../../../../redux/reducers/users";
import { IPage } from "../../../../api/entities/page";
import FilterBar from "./filters";
import { MS_UNIT } from "../../../../constants/common";
import { Formatters } from "../../../../helper/formatters";
import _ from "underscore";
import clsx from "clsx";
import Checkbox from "../../../shared-ui/input/checkbox";
import tableStyles from "../../../shared-ui/table/table.module.scss";
import { useApi } from "../../../../utils/hooks/use-api";

export type AddUsersTableSelected = TableSelected<IUser>;


interface IDataTableProps {
  spaceLimitMb: number;
  hideUsers: number[];
  storagePoolId?: number;
  selected: AddUsersTableSelected;

  setSelected: (selected: AddUsersTableSelected) => void;
}

function DataTable(props: IDataTableProps) {
  const [pageData, setPageData] = useState<IPage<IUser>>(),
    [searchParams, setSearchParams] = useState(USERS_DEFAULT_STATE.page.searchParams),
    [api, loading] = useApi(),
    // eslint-disable-next-line
    loadData = useCallback(
      _.debounce((params: IUsersRequestParams) => {
        api(userService.getPage(params)).then(page => {
          setPageData(page);
        });
      }, 500),
      []
    );

  const columns: IColumnDefinition<IUser, UserSortField>[] = [
    {
      sortKey: UserSortField.Email,
      label: "Users",
      className: styles["col--account"],
      formatter: row => row.email,
    },
    {
      sortKey: UserSortField.Group,
      label: "AD Group",
      className: styles["col--user-group"],
      formatter: row => row.Groups.map(group => group.name).join(", ")
    },
    {
      label: "Space Used",
      className: clsx("hidden-on-tiny-screen", styles["col--size"]),
      formatter: row => Formatters.formatStorageSpace(row.usedSpaceBytes),
    },
  ];

  const checkboxColumn: ICheckboxColumnDefinition<IUser> = {
    formatter: (row, checked, onChange) => {
      if (!checkSpace(row)) {
        return (
          <TooltipQuestion className={styles["tooltip-question"]}>
            This user’s storage usage has exceeded the maximum usage allowed
            by this Storage Profile.
          </TooltipQuestion>
        );
      }
      if (!checkPool(row)) {
        return (
          <TooltipQuestion className={styles["tooltip-question"]}>
            This user uses different Storage Pool
          </TooltipQuestion>
        );
      }
      return (
        <Checkbox
          testId="user-checkbox"
          checked={checked}
          onChange={onChange}
        />
      );
    }
  };

  const getRowClass = (row: IUser) => {
    if (!checkSpace(row) || !checkPool(row)) {
      return tableStyles["row--disabled"];
    }
    return null;
  };

  const updateSearchParams = (sp: IUsersRequestParams) => {
      setSearchParams({
        ...searchParams,
        ...sp,
      });
    },
    checkSpace = (user: IUser) => {
      return props.spaceLimitMb > user.usedSpaceBytes / MS_UNIT.M; //convert to MB
    },
    checkPool = (user: IUser) => {
      return !props.storagePoolId || user.Policy.StoragePool.id === props.storagePoolId;
    },
    onSelectAll = () => {
      const newSelected: AddUsersTableSelected = {};
      pageData?.data.forEach(u => {
        if (checkSpace(u) && checkPool(u)) {
          newSelected[u.id] = u;
        }
      });
      props.setSelected(
        Object.keys(props.selected).length === Object.keys(newSelected).length
          ? {}
          : newSelected
      );
    };

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      excludeUserFilter: props.hideUsers,
    });
    // eslint-disable-next-line
  }, [props.hideUsers]);

  useEffect(() => {
    loadData(searchParams);
  }, [searchParams, loadData]);

  return (
    <>
      <FilterBar onChange={updateSearchParams} />
      <Table
        classes={{row: getRowClass}}
        columns={columns}
        checkboxColumn={checkboxColumn}
        rows={pageData?.data ?? []}
        loading={loading}
        selected={props.selected}
        totalRows={pageData?.count}
        testId="users-table"
        requestParams={searchParams}
        onRequestParamsChanged={updateSearchParams}
        onSelectAll={onSelectAll}
        onSetSelected={props.setSelected}
      />
    </>
  );
}

export default DataTable;
