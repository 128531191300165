import React, { useEffect, useState } from "react";
import StorageProfile from "../../components/storage-profiles/manage-storage-profile/add-storage-profile-container";
import { connect } from "react-redux";
import PageTitle from "../shared-ui/pageTitle";
import ClientDeployment from "./clientDeployment";
import { IAppState } from "../../redux/reducers";
import { LeftNav } from "./navigation/left-nav";
import { policyService } from "../../api/services/policy-service";
import { commonActions } from "../../redux/actions/common";
import { StoragePool } from "./storage-pool/storage-pool";
import { useNavigate } from "../../utils/hooks/use-navigate";
import { useApi } from "../../utils/hooks/use-api";
import { initialSetupActions } from "../../redux/actions/intialSetup";
import { storagePoolService } from "../../api/services/storage-pool-service";

export enum InitialSetupStep {
  StoragePool = 1,
  StorageProfile = 2,
  Finish = 3
}

interface IInitialSetUpContainerProps extends 
  ReturnType<typeof mapDispatchToProps>,
  ReturnType<typeof mapStateToProps> {}

function InitialSetUpContainer(props: IInitialSetUpContainerProps) {
  const [isAnyPolicyExists, setIsAnyPolicyExists] = useState<boolean>(null);
  const navigate = useNavigate();
  const [api] = useApi();

  useEffect(() => {
    props.startLoading();
    api(policyService.getPage({ pageSize: 0 }), null, props.stopLoading, props.stopLoading).then((policiesPage) => {
      setIsAnyPolicyExists(!!policiesPage.count);
    });

    props.startLoading();
    api(storagePoolService.getPage({ pageSize: 0 }), null, props.stopLoading, props.stopLoading).then((poolsPage) => {
      if (poolsPage.count > 0) {
        props.setStep(InitialSetupStep.StorageProfile);
      }
    });
    // eslint-disable-next-line
  }, []);

  if (props.initialSetupSteps === InitialSetupStep.Finish) {
    navigate("/");
  }

  return (
    <>
      <LeftNav />
      <div className="main-content">
        {props.initialSetupSteps === InitialSetupStep.StoragePool && <StoragePool />}
        {props.initialSetupSteps === InitialSetupStep.StorageProfile && (
          <div>
            <PageTitle title="Create Storage Profile" />
            <StorageProfile 
              createDefaultProfile={true}
              isFirstProfile={!isAnyPolicyExists}
            />
          </div>
        )}
        {props.initialSetupSteps === InitialSetupStep.Finish && <ClientDeployment />}
      </div>
    </>
  )
}

const mapStateToProps = (state: IAppState) => ({
  initialSetupSteps: state.initialSetup.initialSetupStep,
});
const mapDispatchToProps = dispatch => {
  return {
    setStep: (step: InitialSetupStep) => {
      dispatch(initialSetupActions.setStep(step));
    },
    startLoading: () => {
      dispatch(commonActions.startLoading());
    },
    stopLoading: () => {
      dispatch(commonActions.stopLoading());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InitialSetUpContainer);
