import { AllStorageProps, IAddStorageNodeRequest, IStorageNodeTestConnectionRequest, IUpdateStorageNodeRequest, S3CompatibleVendor, StorageProvider } from "./../api/entities/storage-node";
import { IStorageNode } from "../api/entities/storage-node";
import _ from "underscore";
import { ABSRegions, AWSRegions, GoogleRegions } from "../helper/regions";

const storageProviderToNameMap = {
  [StorageProvider.S3]: "Amazon S3",
  [StorageProvider.GCS]: "Google Cloud Storage",
  [StorageProvider.ABS]: "Azure Blob Storage",
  [StorageProvider.S3Compatible]: "Amazon S3 Compatible",
};

const storageProviderToShortNameMap = {
  [StorageProvider.S3]: "AWS S3",
  [StorageProvider.GCS]: "Google",
  [StorageProvider.ABS]: "Azure",
  [StorageProvider.S3Compatible]: "AWS S3 Compatible",
};

export const storageProviderToSecretFieldLabelMap = {
  [StorageProvider.S3]: "Access Key Secret",
  [StorageProvider.S3Compatible]: "Access Key Secret",
  [StorageProvider.GCS]: "Private Key",
  [StorageProvider.ABS]: "Access Key",
};

export const storageProviderToRegionFieldLabelMap = {
  [StorageProvider.S3]: "Region",
  [StorageProvider.S3Compatible]: "Region",
  [StorageProvider.GCS]: "Region",
  [StorageProvider.ABS]: "Location",
};

export enum StorageProviderField {
  StorageName = "storageName",
  Region = "region",
  Bucket = "bucket",
  AccountName = "account_name",
  Container = "container",
  Arn = "arn",
  ProjectId = "project_id",
  AccessKey = "key",
  SecretKey = "secret",
  ClientEmail = "client_email",
  Vendor = "vendor",
  EndpointURL = "endpointURL"
}

type StorageProviderFieldInfo = {
  providers?: StorageProvider[];
  vendors?: S3CompatibleVendor[];
};

type StorageNodeRequest = IAddStorageNodeRequest | IUpdateStorageNodeRequest | IStorageNodeTestConnectionRequest;

const storageProviderFieldsInfo: {[key in StorageProviderField]: StorageProviderFieldInfo} = {
  [StorageProviderField.StorageName]: { },
  [StorageProviderField.Region]: {
    providers: [
      StorageProvider.S3, 
      StorageProvider.ABS, 
      StorageProvider.GCS
    ]
  },
  [StorageProviderField.Bucket]: {
    providers: [
      StorageProvider.S3, 
      StorageProvider.S3Compatible, 
      StorageProvider.GCS
    ]
  },
  [StorageProviderField.AccountName]: {
    providers: [
      StorageProvider.ABS,
    ]
  },
  [StorageProviderField.Container]: {
    providers: [
      StorageProvider.ABS,
    ]
  },
  [StorageProviderField.Arn]: {
    providers: [
      StorageProvider.S3, 
    ]
  },
  [StorageProviderField.ProjectId]: {
    providers: [
      StorageProvider.GCS
    ]
  },
  [StorageProviderField.AccessKey]: {
    providers: [
      StorageProvider.S3,
      StorageProvider.S3Compatible
    ]
  },
  [StorageProviderField.SecretKey]: {},
  [StorageProviderField.ClientEmail]: {
    providers: [
      StorageProvider.GCS
    ],
  },
  [StorageProviderField.Vendor]: {
    providers: [
      StorageProvider.S3Compatible
    ]
  },
  [StorageProviderField.EndpointURL]: {
    providers: [
      StorageProvider.S3Compatible
    ],
    vendors: [
      S3CompatibleVendor.MinIO
    ]
  }
};

export class StorageUtils {
  getStorageContentTypes(storageNode: IStorageNode): string[] {
    const result: string[] = [];
    if (!_.isEmpty(storageNode.ContentStoragePool)) {
      result.push("Content");
    }
    if (!_.isEmpty(storageNode.MetaStoragePool)) {
      result.push("Metadata");
    }
    if (!_.isEmpty(storageNode.KeyStoragePool)) {
      result.push("Key");
    }
    return result;
  }

  getStorageProviderName(storageProvider: StorageProvider): string {
    return storageProviderToNameMap[storageProvider];
  }

  getStorageProviderShortName(storageProvider: StorageProvider): string {
    return storageProviderToShortNameMap[storageProvider];
  }

  getProviderRegions(provider: StorageProvider): string[] {
    switch (provider) {
      case StorageProvider.S3:
      case StorageProvider.S3Compatible:
        return [...AWSRegions];
      case StorageProvider.ABS:
        return [...ABSRegions];
      case StorageProvider.GCS:
        return [...GoogleRegions];
      default: 
        return [];
    }
  }

  isCustomRegion(provider: StorageProvider, region: string): boolean {
    return this.getProviderRegions(provider).indexOf(region) < 0;
  }

  isFieldVisible(field: StorageProviderField, provider: StorageProvider, vendor?: S3CompatibleVendor): boolean {
    const info = storageProviderFieldsInfo[field];
    return (info && (!info.providers || info.providers.includes(provider))) && 
      (!info.vendors || info.vendors.includes(vendor));
  }

  cleanNotApplicableFields<TRequest extends StorageNodeRequest>(request: TRequest): TRequest {
    Object.keys(storageProviderFieldsInfo).forEach((field: StorageProviderField) => {
      if (request[field] && !this.isFieldVisible(field, request.type, request.vendor)) {
        request[field] = null;
      }
    });
    return request;
  }

  isTestConnectionRequestValid(request: IStorageNodeTestConnectionRequest): boolean {
    return Object.keys(storageProviderFieldsInfo).every((field: StorageProviderField) => {
      return field === StorageProviderField.StorageName || request[field] || !this.isFieldVisible(field, request.type, request.vendor);
    });
  }

  getConnectionRequestForNode(node: IStorageNode): IStorageNodeTestConnectionRequest {
    const props: AllStorageProps = JSON.parse(node.props);
    return {
      account_name: props.account_name,
      arn: props.roleArn,
      bucket: props.bucket,
      client_email: props.client_email,
      container: props.container,
      endpointURL: props.endpointURL,
      key: props.key,
      project_id: props.project_id,
      region: props.region,
      secret: "",
      type: node.StorageTypeID,
      vendor: props.vendor
    };
  }
}

export const storageUtils = new StorageUtils();
