import React, { useEffect, useState } from "react";
import { IIdName } from "../../../../api/entities/id-name";
import { SortDirection } from "../../../../api/entities/page";
import { StoragePoolSortField } from "../../../../api/entities/storage-pool";
import { storagePoolService } from "../../../../api/services/storage-pool-service";
import { useApi } from "../../../../utils/hooks/use-api";
import { routeUtils } from "../../../../utils/route-utils";
import Button from "../../../shared-ui/button";
import IOption from "../../../shared-ui/common/option";
import { Select } from "../../../shared-ui/select";
import SidePanelSection from "../../../shared-ui/side-panel-section/side-panel-section";
import styles from "./pool-section.module.scss";

export interface IPoolSectionProps {
  storagePool: IIdName;
  isReadonly?: boolean;
  autoFocus?: boolean;
  onChange?: (storagePool: IIdName) => void;
}

export const PoolSection = (props: IPoolSectionProps) => {
  const [options, setOptions] = useState<IOption[]>([]);
  const [api] = useApi();

  useEffect(() => {
    if (!props.isReadonly) {
      api(storagePoolService.getPage({ fields: ["id", "name"], order: SortDirection.Ascending, field: StoragePoolSortField.Name })).then((page) => {
        setOptions(page.data.map(pool => ({
          value: pool.id,
          label: pool.name
        })));
      })
    }

    // eslint-disable-next-line
  }, [props.isReadonly]);

  return (
    <SidePanelSection testId="storagePool" title="Storage Pool">
      {props.isReadonly && (
        <Button
          testId="storage-pool-link"
          colors="link" 
          href={routeUtils.getRouteToStoragePoolDetails(props.storagePool?.id)}>
          {props.storagePool?.name}
        </Button>
      )}
      {!props.isReadonly && (
        <Select
          testId="storage-pool-select"
          autoFocus={props.autoFocus}
          className={styles.select}
          placeholder="Choose one..."
          options={options}
          value={props.storagePool?.id}
          onChange={(poolId) => {
            const option = options.find(o => o.value === poolId);
            props.onChange(option && { id: option.value, name: option.label});
          }}
        />
      )}
    </SidePanelSection>
  );
};