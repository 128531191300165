import { IUserShort } from './user';
import { IRequestParams } from './page';
import { IConditionalUpdateRequest } from '../services/api-service';
import { IIdName } from './id-name';
import { IRepoDataMigration } from './data-migration';

export interface ISharingProfileShort {
    id: number;
    StoragePool: IIdName;
    consoleName: string;
    folderName: string;
    canEdit: boolean;
    usersCount?: number;
    sharingInstancesCount?: number;
    createdAt: string;
    updatedAt: string;
    dataMigrations?: IRepoDataMigration[];
}

export interface ISharingProfile extends ISharingProfileShort {
    users: IUserShort[];
}

export interface ISharingProfileFilterParams {
    q?: string;
    storagePoolFilter?: number;
}

export interface ISharingProfilesRequestParams extends 
    IRequestParams<SharingProfileSortField>,
    ISharingProfileFilterParams {
}

export interface ISharingProfileCreateRequest {
    storagePoolId: number;
    consoleName: string;
    folderName: string;
    canEdit: boolean;
    ignoreFolderName?: boolean;
    users: { id: number }[];
}

export interface ISharingProfileUpdateRequest extends Omit<ISharingProfileCreateRequest, "storagePoolId">, IConditionalUpdateRequest {
    id: number;
}

export interface IUpdateUsersRequest extends IConditionalUpdateRequest, Pick<ISharingProfileCreateRequest, "users"> { }

export interface ISharingProfileDeleteRequest {
    ids: { id: number, lastUpdated: string }[];
}

export enum SharingProfileSortField {
    ConsoleName = "consoleName",
    UsersCount = "usersCount",
    FolderName = "folderName",
    StoragePool = "storagepool"
}

export interface IUserSharingInstances {
    user: IUserShort;
    sharingInstances: { id: number, folderName: string }[];
}