import React, { useEffect, useState } from "react";
import { IStorageNode } from "../../../api/entities/storage-node";
import { IStoragePool, IStoragePoolCreateRequest, IStoragePoolUpdateRequest } from "../../../api/entities/storage-pool";
import { CONFIG } from "../../../config/config";
import { formUtils, IFormField } from "../../../utils/form-utils";
import { routeUtils } from "../../../utils/route-utils";
import { storagePoolUtils } from "../../../utils/storage-pool-utils";
import { RestoreFilesSection } from "../../common/restore-files-section/restore-files-section";
import { FormPanel } from "../../shared-ui/form/form-panel/form-panel";
import { StorageNodePanel, StorageNodePanelMode } from "../../storage-pools/panels/storage-node-panel/storage-node-panel";
import { PoolNameSection } from "../../storage-pools/sections/pool-name-section/pool-name-section";
import { IStoragePoolNode, StorageNodesSection } from "../../storage-pools/sections/storage-nodes-section/storage-nodes-section";
import { StorageReconstructionSection } from "../../storage-pools/sections/storage-reconstruction-section/storage-reconstruction-section";
import { IStorageNodeTypes } from "../../storage-pools/sections/storage-type-section/storage-type-section";
import styles from "./storage-pool-form.module.scss";

export enum StoragePoolFormMode {
  Create = 1,
  Edit = 2
}

export interface IStoragePoolFromProps {
  storagePoolId?: number;
  form: IStoragePoolForm;
  mode: StoragePoolFormMode;
  onCreateStoragePool: (createRequest: IStoragePoolCreateRequest) => Promise<IStoragePool>;
  onUpdateStoragePool?: (updateRequest: IStoragePoolUpdateRequest) => Promise<IStoragePool>;
}

export interface IStoragePoolFormValue {
  name: string;
  storagePoolNodes: IStoragePoolNode[];
  contentSecurityLevel: number;
  keySecurityLevel: number;
  metaSecurityLevel: number;
}

export interface IStoragePoolForm {
  name: IFormField<string>;
  storagePoolNodes: IFormField<IStoragePoolNode[]>;
  contentSecurityLevel: IFormField<number>;
  keySecurityLevel: IFormField<number>;
  metaSecurityLevel: IFormField<number>;
}

export const storagePoolFormDefaultValue: IStoragePoolFormValue = {
  name: "",
  storagePoolNodes: [],
  contentSecurityLevel: CONFIG.minSecurityLevel,
  keySecurityLevel: CONFIG.minSecurityLevel,
  metaSecurityLevel: CONFIG.minSecurityLevel,
};


export function StoragePoolForm(props: IStoragePoolFromProps) {
  const { form } = props;

  const [storageNodePanelMode, setStorageNodePanelMode] = useState<StorageNodePanelMode>(null);
  const [selectedStorageNode, setSelectedStorageNode] = useState<IStoragePoolNode>(null);

  const [nodesCount, setNodesCount] = useState({
    content: CONFIG.minSecurityLevel, 
    key: CONFIG.minSecurityLevel, 
    meta: CONFIG.minSecurityLevel
  });
  const formError = formUtils.getFormError(form);

  function handleEditStorageNode(poolNode: IStoragePoolNode) {
    setStorageNodePanelMode(props.mode === StoragePoolFormMode.Create ? StorageNodePanelMode.FullEdit : StorageNodePanelMode.LimitedEdit);
    setSelectedStorageNode(poolNode);
  }

  function handleReplaceStorageNode(poolNode: IStoragePoolNode) {
    setStorageNodePanelMode(StorageNodePanelMode.Replacement);
    setSelectedStorageNode(poolNode);
  }
  
  function handleAddStorageNode() {
    setStorageNodePanelMode(StorageNodePanelMode.Add);
  }

  function handleCloseStorageNodePanel() {
    setStorageNodePanelMode(null);
    setSelectedStorageNode(null);
  }

  function handleStorageNodeSaved(storageNode: IStorageNode, storageNodeTypes: IStorageNodeTypes) {
    const nodes = form.storagePoolNodes.value;
    if (storageNodePanelMode === StorageNodePanelMode.FullEdit || storageNodePanelMode === StorageNodePanelMode.LimitedEdit) {
      nodes[nodes.indexOf(selectedStorageNode)] = { node: storageNode, types: storageNodeTypes };
      form.storagePoolNodes.onChange([...nodes]);
    }
    else if (storageNodePanelMode === StorageNodePanelMode.Add) {
      form.storagePoolNodes.onChange([...nodes, { node: storageNode, types: storageNodeTypes }]);
    }
    else if (storageNodePanelMode === StorageNodePanelMode.Replacement) {
      selectedStorageNode.node.replacedByStorageNodeID = storageNode.id;
      form.storagePoolNodes.onChange([...nodes, { node: storageNode, types: storageNodeTypes }]);
    }
  }

  function save() {
    formUtils.validateAll(form).then((isValid) => {
      if (isValid) {
        const formValue = formUtils.getFormValue<IStoragePoolFormValue>(form);
        if (props.mode === StoragePoolFormMode.Create) {
          props.onCreateStoragePool({
            name: formValue.name,
            ContentSecurityLevel: formValue.contentSecurityLevel,
            KeySecurityLevel: formValue.keySecurityLevel,
            MetaSecurityLevel: formValue.metaSecurityLevel,
            contentStorages: formValue.storagePoolNodes.filter(n => n.types.storeContent).map(n => n.node.id),
            keyStorages: formValue.storagePoolNodes.filter(n => n.types.storeKey).map(n => n.node.id),
            metaStorages: formValue.storagePoolNodes.filter(n => n.types.storeMetadata).map(n => n.node.id),
          });
        }
        else if (props.mode === StoragePoolFormMode.Edit) {
          props.onUpdateStoragePool({
            id: props.storagePoolId,
            name: formValue.name
          });
        }
      }
    });
  }

  useEffect(() => {
    const nodes = form.storagePoolNodes.value;
    const _nodesCount: typeof nodesCount = {
      content: nodes.filter(node => node.types.storeContent).length, 
      key: nodes.filter(node => node.types.storeKey).length, 
      meta: nodes.filter(node => node.types.storeMetadata).length, 
    };

    function updateSecurityLevel(securityLevelField: IFormField<number>, nodesCount: number) {
      const updatedValue = Math.max(Math.min(securityLevelField.value, nodesCount - 1), CONFIG.minSecurityLevel);
      if (updatedValue !== securityLevelField.value) {
        securityLevelField.onChange(updatedValue);  
      }
    }

    updateSecurityLevel(form.contentSecurityLevel, _nodesCount.content);
    updateSecurityLevel(form.keySecurityLevel, _nodesCount.key);
    updateSecurityLevel(form.metaSecurityLevel, _nodesCount.meta);

    setNodesCount(_nodesCount);

     // eslint-disable-next-line
  }, [form.storagePoolNodes.value]);
  
  useEffect(() => {
    form.storagePoolNodes.validate(form.storagePoolNodes.value);
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <FormPanel
        className={styles.root}
        okButtonText="Save"
        okButtonClick={save}
        okButtonDisabled={!!formError}
        errorMessage={formError}
      >
        <PoolNameSection name={form.name}/>
        <StorageNodesSection
          mode={props.mode}
          storagePoolNodes={form.storagePoolNodes} 
          onAddStorageNode={handleAddStorageNode}
          onEditStorageNode={handleEditStorageNode}
          onReplaceStorageNode={handleReplaceStorageNode}
        />
        <StorageReconstructionSection
          isReadOnly={props.mode === StoragePoolFormMode.Edit}
          contentSecurityLevel={form.contentSecurityLevel}
          keySecurityLevel={form.keySecurityLevel}
          metaSecurityLevel={form.metaSecurityLevel}
          maxContentSecurityLevel={Math.max(nodesCount.content - 1, CONFIG.minSecurityLevel)}
          maxKeySecurityLevel={Math.max(nodesCount.key - 1, CONFIG.minSecurityLevel)}
          maxMetaSecurityLevel={Math.max(nodesCount.meta - 1, CONFIG.minSecurityLevel)}
        />
        {!!props.storagePoolId && storagePoolUtils.canRestoreFiles() && (
          <RestoreFilesSection
            description="Restore files in this Storage Pool after a security breach or loss."
            restoreFilesRoute={routeUtils.getRouteToStoragePoolRestoreFiles(props.storagePoolId)}
          />
        )}
      </FormPanel>
      <StorageNodePanel
        mode={storageNodePanelMode}
        storagePoolNode={selectedStorageNode}
        onClose={handleCloseStorageNodePanel}
        onNodeSaved={handleStorageNodeSaved}
      />
    </>
  );
}