import React from "react";
import Button from "../../shared-ui/button";
import FormSection from "../../shared-ui/form/form-section/form-section";
import styles from "./restore-files-section.module.scss";

export interface IRestoreFilesSectionProps {
  restoreFilesRoute: string;
  description: string;
}

export function RestoreFilesSection(props: IRestoreFilesSectionProps) {
  return (   
    <FormSection 
      testId="restore-files-section" 
      title="Restore Files"
      description={props.description}>
        <Button
          testId="restore-files-button"
          colors="primary"
          href={props.restoreFilesRoute}
          className={styles["restore-files-button"]}
        >
          RESTORE FILES
        </Button>
    </FormSection>
  );
}