import React, { ReactNode } from "react";
import { ConfirmDialog } from "../../../shared-ui/dialogs/common/confirm-dialog/confirm-dialog";
import styles from "./change-pool-confirmation.module.scss";

export enum ChangePoolReason {
  StorageProfileChangePool = "storageProfileChangePool",
  SharingProfileChangePool = "sharingProfileChangePool",
  AddUsersToCurrentProfile = "addUsersToCurrentProfile",
  MoveUsersToTargetProfile = "moveUsersToTargetProfile",
  CreateNewProfile = "createNewProfile"
}

const changePoolReasonToTitleMap: {[key in ChangePoolReason]: string} = {
  [ChangePoolReason.StorageProfileChangePool]: "Change Storage Pool",
  [ChangePoolReason.SharingProfileChangePool]: "Change Storage Pool",
  [ChangePoolReason.AddUsersToCurrentProfile]: "Add Users",
  [ChangePoolReason.MoveUsersToTargetProfile]: "Move Users",
  [ChangePoolReason.CreateNewProfile]: "Save Profile",
}

function getMessageByChangePoolReason(changePoolReason: ChangePoolReason): ReactNode {
  switch (changePoolReason) {
    case ChangePoolReason.StorageProfileChangePool: 
      return (
        <>
          Do you want to migrate the files of all users associated 
          with this Storage Profile to the new Storage Pool? <br/>
          This migration will take time and users will be unable to 
          use Myota during the process.
        </>
      );
    case ChangePoolReason.SharingProfileChangePool: 
      return (
        <>
          Do you want to migrate the files of all users associated 
          with this Sharing Profile to the new Storage Pool? <br/>
          This migration will take time and users will be unable to 
          use Myota during the process.
        </>
      );
    case ChangePoolReason.AddUsersToCurrentProfile:
    case ChangePoolReason.MoveUsersToTargetProfile:
    case ChangePoolReason.CreateNewProfile: 
        const reasonToProfileTypeMap = {
          [ChangePoolReason.AddUsersToCurrentProfile]: "current",
          [ChangePoolReason.MoveUsersToTargetProfile]: "target",
          [ChangePoolReason.CreateNewProfile]: "new",
        };
        return (
          <>
            The selected users will be migrated to the storage pool
            associated with the {reasonToProfileTypeMap[changePoolReason]} profile. This migration will take 
            time and users will be unable to use Myota during the 
            process.
          </>
        );
  }
}

export interface IChangePoolConfirmationProps {
  isOpen: boolean;
  changePoolReason: ChangePoolReason;
  onConfirm: () => void;
  onClose: () => void;
}

export function ChangePoolConfirmation(props: IChangePoolConfirmationProps) {

  return (
    <ConfirmDialog
      testId={`change-pool-confirmation-${props.changePoolReason}`}
      title={changePoolReasonToTitleMap[props.changePoolReason]}
      confirmText="MIGRATE FILES"
      open={props.isOpen}
      onClose={props.onClose}
      onConfirm={() => { 
        props.onConfirm(); 
        props.onClose(); }
      }
    >
      <div className={styles.content}>
        {getMessageByChangePoolReason(props.changePoolReason)}
      </div>
    </ConfirmDialog>
  );
}
