export const COGNITO_ROUTE = "/cognito";
export const LOGIN_ROUTE = "/login";
export const DEVICES_ROUTE = "/devices";
export const STORAGE_PROFILES_ROUTE = "/storage-profiles";
export const INITIAL_SETUP_ROUTE = "/initial-setup";
export const LOGOUT_ROUTE = "/logout";
export const LOGOUT_SUCCESS_ROUTE = "/logout-success";
export const SCAN_HISTORY_ROUTE = "/scan-history";
export const USERS_ROUTE = "/users";
export const USERS_FILTER_ROUTE = `${USERS_ROUTE}/filter/:filter`;
export const SHARING_PROFILES_ROUTE = "/sharing-profiles";
export const SHARING_INSTANCES_ROUTE = "/sharing-instances";
export const STORAGE_POOLS_ROUTE = "/storage-pools";
export const ADDONS_SETTINGS_ROUTE = "/addons";
export const PROFILE_ROUTE = "/profile";
export const CITRIX_SHARE_FILES_ROUTE = "/addons/citrix-share-files";
export const SETTINGS_ROUTE = "/settings";
export const USER_GROUPS_ROUTE = "/user-groups";


export enum UserGroupRouteParam {
  Id = "id",
}

export enum StorageProfileRouteParam {
  Id = "id",
  Action = "action"
}

export enum SettingsRouteParam {
  Section = "section",
  Id = "id",
  Action = "action"
}

export enum SettingsRouteSection {
  AutoApprovalCertificate = "auto-approval-certificate",
  AutoApprovalCertificateGuide = "auto-approval-certificate-guide",
  ActiveDirectorySettings = "active-directory-settings",
  CliCredentials = "cli-credentials"
}

export enum SettingsRouteAction {
  Guide = "guide"
}

export enum StorageProfileRouteAction {
  ManageUsers = "users",
  ManageExceededUsers = "exceeded-users"
}

export enum StoragePoolRouteParam {
  IdOrAction = "idOrAction",
  NodeIdOrAction = "nodeIdOrAction",
}

export enum SharingInstanceRouteParam {
  IdOrAction = "idOrAction",
  Action = "action"
}

export enum StoragePoolRouteAction {
  ManageNode = "node",
  RestoreFiles = "restore-files"
}

export enum SharingInstanceRouteAction {
  ManageUsers = "users",
  RestoreFiles = "restore-files"
}

export enum SharingProfileRouteParam {
  Id = "id",
  Action = "action"
}

export enum SharingProfileRouteAction {
  ManageUsers = "users"
}

export enum DevicesRouteParam {
  Id = "id",
  Action = "action"
}

export enum DevicesRouteAction {
  LoginHistory = "login-history"
}

export enum UsersRouteParam {
  UidOrAction = "uidOrAction",
  Filter = "filter",
  Action = "action"
}

export enum UsersRouteAction {
  MigrateFiles = "migrate-files",
  RestoreFiles = "restore-files"
}

export interface IDeviceRouteParams {
  [DevicesRouteParam.Id]: string;
  [DevicesRouteParam.Action]: string;
}

