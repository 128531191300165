import {
  IUsersRequestParams,
  IUserDetails,
  ICurrentUser,
  UserSortField,
  IUserCommon,
} from "../../api/entities/user";
import { UsersAction, UsersActionType, USER_ENTITY_NAME } from "../actions/users";
import { IPolicyShort } from "../../api/entities/policy";
import { createEntityReducer, getEntityDefaultState, IEntityState } from "./entity";
import { SortDirection } from "../../api/entities/page";

export interface IUsersState extends IEntityState<IUserCommon, UserSortField, IUsersRequestParams> {
  loading: boolean;
  error: string;
  userPolicies: IPolicyShort[];
  userDetail: IUserDetails;
  userHistory: any[];
  currentUser: ICurrentUser;
  sessionExpired: boolean;
}

export const USERS_DEFAULT_STATE: IUsersState = {
  ...getEntityDefaultState(UserSortField.Email, SortDirection.Ascending),
  loading: false,
  error: "",
  userPolicies: [],
  userHistory: [],
  userDetail: {} as IUserDetails,
  currentUser: null,
  sessionExpired: false,
};

const entityReducer = createEntityReducer(USER_ENTITY_NAME);

export default function users(
  state = USERS_DEFAULT_STATE,
  action: UsersAction
): IUsersState {
  state = entityReducer(state, action);
  switch (action.type) {
    case UsersActionType.FetchDetail:
      return {
        ...state,
        userDetail: action.user,
      };
    case UsersActionType.BlockUser:
      let userArray = [...state.page.entities];
      let userIndex = userArray.findIndex((x) => x.id === action.id);
      if (userIndex >= 0) {
        userArray[userIndex].status = action.flag;
      }
      return {
        ...state,
        page: {
          ...state.page,
          entities: userArray,
        },
      };
    case UsersActionType.FetchUserPolicies:
      return {
        ...state,
        userPolicies: action.policies,
      };
    case UsersActionType.FetchCurrentUser:
      return {
        ...state,
        currentUser: action.user,
        sessionExpired: false,
      };
    case UsersActionType.UserLoggedIn:
      return {
        ...state,
        currentUser: { ...state.currentUser, uid: action.loginInfo.uid },
        sessionExpired: false,
      };
    case UsersActionType.SessionExpired:
      return {
        ...state,
        currentUser: null,
        sessionExpired: true,
      };
    default:
      return state;
  }
}
