export enum SortDirection {
  Ascending = "asc",
  Descending = "desc",
}

export const sortDirectionToNameMap = {
  [SortDirection.Ascending]: "Ascending",
  [SortDirection.Descending]: "Descending",
};

export interface IPage<TEntity> {
  data: TEntity[];
  count: number;
}

export interface IPageRequestParams {
  offset?: number;
  pageSize?: number;
}

export interface ISortRequestParams<T> {
  field?: T;
  order?: SortDirection;
}

export interface IRequestParams<TSortField=string> extends IPageRequestParams, ISortRequestParams<TSortField> {
}
