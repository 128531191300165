import { PickActions, commonActions } from "./common";
import { accountService } from "../../api/services/account-service";
import { usersActions, usersThunkActions } from "./users";

export enum AccountActionType {
  Login = "ACCOUNT_LOGIN",
  LoginError = "ACCOUNT_LOGIN_ERROR",
  RemoveLoginError = "ACCOUNT_REMOVE_LOGIN_ERROR",
}

class AccountActions {
  loginErrorAction() {
    return {
      type: AccountActionType.LoginError as typeof AccountActionType.LoginError,
    };
  }
  removeLoginErrorAction() {
    return {
      type: AccountActionType.RemoveLoginError as typeof AccountActionType.RemoveLoginError,
    };
  }
}

export const accountActions = new AccountActions();
export type AccountAction = PickActions<AccountActions>;

//----------------api call to login Cognito Users--------------------//
export function loginCognitoUser(code: string, redirectUri?: string) {
  return (dispatch) => {
    dispatch(commonActions.startLoading());
    dispatch(accountActions.removeLoginErrorAction());
    return accountService
      .loginCognitoUser(code, redirectUri)
      .then((loginInfo) => {
        if (loginInfo) {
          dispatch(usersActions.userLoggedInAction(loginInfo));
          dispatch(usersThunkActions.getCurrentUser());
        } else {
          dispatch(accountActions.loginErrorAction());
        }
      })
      .catch((_) => dispatch(accountActions.loginErrorAction()))
      .finally(() => dispatch(commonActions.stopLoading()));
  };
}
