import { IIdConsoleName, IIdName } from './id-name';
import { IRequestParams } from './page';
import { IStorageNode } from './storage-node';
export interface IStoragePool {
    id: number;
    name: string;
    ContentRedundancyLevel: number;
    KeyRedundancyLevel: number;
    MetaRedundancyLevel: number;
    ContentSecurityLevel: number;
    KeySecurityLevel: number;
    MetaSecurityLevel: number;
    ContentStorageNodes: IStorageNode[];
    KeyStorageNodes: IStorageNode[];
    MetaStorageNodes: IStorageNode[];
    Policies: IIdName[];
    SharingProfiles: IIdConsoleName[];
}

export interface IStoragePoolShort {
    id: number;
    name: string;
    usageBytes?: number;
}

export interface IStoragePoolCreateRequest {
    name: string;
    keyStorages: number[];
    contentStorages: number[];
    metaStorages: number[];
    ContentSecurityLevel: number; 
    KeySecurityLevel: number; 
    MetaSecurityLevel: number;
}

export interface IStoragePoolUpdateRequest {
    id: number;
    name: string;
}


export interface IStoragePoolsRequestParams extends 
    IRequestParams<StoragePoolSortField>, 
    IStoragePoolFilterParams {
    fields?: (keyof IStoragePoolShort)[]
}

export interface IStoragePoolFilterParams {
    q?: string,
}

export enum StoragePoolSortField {
    Name = "name",
    Id = "id",
    Usage = "usage",
}