import { IDataMigrationState } from './data-migration';
import { ISharingProfileState, sharingProfile } from './sharing-profile';
import { ISharingInstanceState, sharingInstance } from './sharing-instance';
import { combineReducers } from "redux";
import users, { IUsersState } from "./users";
import devices, { IDevicesState } from "./devices";
import storage, { IStorageState } from "./storage";
import common, { ICommonState } from "./common";
import storageProfile, { IStorageProfileState } from "./storage-profile";
import initialSetup, { IInitialSetupState } from "./intialSetup";
import dashboard, { IDashboardState } from "./dashboard";
import account, { IAccountState } from "./account";
import { dataMigration } from "./data-migration";
import { IRequestParams } from '../../api/entities/page';
import { IStoragePoolState, storagePool } from './storage-pool';
import { IUserGroupState, userGroup } from './user-group';

const userApp = combineReducers({
  users,
  devices,
  storage,
  common,
  storageProfile,
  initialSetup,
  dashboard,
  account,
  sharingProfile,
  sharingInstance,
  storagePool,
  dataMigration,
  userGroup
});

export interface IAppState {
  users: IUsersState;
  devices: IDevicesState;
  storage: IStorageState;
  common: ICommonState;
  storageProfile: IStorageProfileState;
  initialSetup: IInitialSetupState;
  dashboard: IDashboardState;
  account: IAccountState;
  sharingProfile: ISharingProfileState;
  sharingInstance: ISharingInstanceState;
  storagePool: IStoragePoolState;
  dataMigration: IDataMigrationState;
  userGroup: IUserGroupState;
}

export interface IDialogState<TDialogData> {
  isOpen: boolean;
  data: TDialogData;
}

export interface IEntitiesPageState<TEntity, TSortField, TSearchParams extends IRequestParams<TSortField>> {
  entities: TEntity[];
  searchParams: TSearchParams;
  count: number;
  isLoading: boolean;
}

export default userApp;
