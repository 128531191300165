import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import _ from "underscore";
import { IEntity } from "../../api/entities/id-name";
import { IPage, IPageRequestParams } from "../../api/entities/page";
import { IEntityService } from "../../api/services/entity-service";
import { IAppState } from "../reducers";
import { IEntityState } from "../reducers/entity";
import { catchException, handleApi } from "./common";

export enum EntityActionType {
  FetchPageRequest = "FETCH_PAGE_REQUEST",
  FetchPageSuccess = "FETCH_PAGE_SUCCESS",
  FetchPageFail = "FETCH_PAGE_FAIL",
  SetSelection = "SET_SELECTION",
}

export function formatAction(entityName: string, actionType: EntityActionType): string {
  return `${entityName}_${actionType}`;
}

export type FetchPageRequestActionType<TPageRequestParams extends IPageRequestParams> = {
  type: string;
  pageRequestParams: TPageRequestParams;
}

export type FetchPageSuccessActionType<TEntity extends IEntity> = {
  type: string;
  page: IPage<TEntity>;
}

export type FetchPageFailActionType = {
  type: string;
}

export type SetSelectionActionType<TEntity extends IEntity> = {
  type: string;
  selection: Record<number, TEntity>
}

export class EntityActions<TEntity extends IEntity, TPageRequestParams extends IPageRequestParams> {

  constructor(private readonly entityName: string) {
  }

  fetchPageRequest(pageRequestParams: TPageRequestParams): FetchPageRequestActionType<TPageRequestParams> {
    return {
      type: formatAction(this.entityName, EntityActionType.FetchPageRequest),
      pageRequestParams
    };
  }
  fetchPageSuccess(page: IPage<TEntity>): FetchPageSuccessActionType<TEntity> {
    return {
      type: formatAction(this.entityName, EntityActionType.FetchPageSuccess),
      page
    };
  }
  fetchPageFail(): FetchPageFailActionType {
    return {
      type: formatAction(this.entityName, EntityActionType.FetchPageFail),
    };
  }
  setSelection(selection: Record<number, TEntity>): SetSelectionActionType<TEntity> {
    return {
      type: formatAction(this.entityName, EntityActionType.SetSelection),
      selection,
    };
  }
}

export class EntityThunkActions<TEntity extends IEntity, TSortField, TPageRequestParams extends IPageRequestParams> {
  private readonly entityActions: EntityActions<TEntity, TPageRequestParams>;

  constructor(
    private readonly entityName: string, 
    private readonly entityService: IEntityService<TEntity, TPageRequestParams>,
    private readonly canSelect?: (entity: TEntity) => boolean) {
    this.entityActions = new EntityActions<TEntity, TPageRequestParams>(entityName);
  }

  fetchPage(pageRequestParams?: TPageRequestParams) {
    return (dispatch, getState: () => IAppState) => {
      dispatch(this.entityActions.fetchPageRequest(pageRequestParams));
      const entityState: IEntityState<TEntity, TSortField, TPageRequestParams> = getState()[this.entityName];
      return handleApi(this.entityService
        .getPage(entityState.page.searchParams)
        .then(page => {
          dispatch(this.entityActions.fetchPageSuccess(page));
        }), false, apiError => {
          dispatch(this.entityActions.fetchPageFail());
          catchException()(apiError);
        });
    };
  }

  selectAll(): ThunkAction<void, IAppState, null, Action> {
    return (dispatch, getState: () => IAppState) => {
      const state: IEntityState<TEntity, TSortField, TPageRequestParams> = getState()[this.entityName];
      if (state.page.count === state.page.entities.length) {
        dispatch(
          this.entityActions.setSelection(
            _.indexBy(this.canSelect ? state.page.entities.filter(this.canSelect) : state.page.entities, p => p.id)
          )
        );
      } else {
        const searchParams = {
          ...state.page.searchParams,
          offset: null,
          pageSize: null,
        };
        handleApi(this.entityService
          .getPage(searchParams)
          .then(page => {
            dispatch(
              this.entityActions.setSelection(_.indexBy(this.canSelect ? page.data.filter(this.canSelect) : page.data, p => p.id))
            );
          }), true);
      }
    };
  }
}
