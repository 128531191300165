import { IUserLoginInfo } from "../entities/user";
import { DataService } from "./data-service";

class AccountService extends DataService {
  constructor() {
    super("account");
  }

  loginCognitoUser(code: string, redirectUri?: string) {
    const params: any = { code };
    if (redirectUri) {
      params.redirect_uri = redirectUri;
    }
    return this.api.post<IUserLoginInfo>("cognito/session", params);
  }
}

export const accountService = new AccountService();
