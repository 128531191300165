import React, { ReactNode, useState } from "react";
import styles from "./tooltip-input.module.scss";
import inputStyles from "../../styles/input.module.scss";
import IComponentProps from "../../common/component-props";
import clsx from "clsx";
import Tooltip from "../../tooltip";

interface ITooltipInputProps extends IComponentProps {
  autoFocus?: boolean;
  value: string | number;
  tooltip?: ReactNode;
  type?: string;
  min?: number;
  max?: number;

  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
}

export default function TooltipInput(props: ITooltipInputProps) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  return (
    <div
      className={clsx(
        styles.root,
        {
          [styles["root--tooltip"]]: !!props.tooltip,
        },
        props.className
      )}
      style={props.style}
      data-testid={props.testId}
    >
      <input
        type={props.type || "text"}
        min={props.min}
        max={props.max}
        className={clsx(inputStyles.root, styles.input)}
        value={props.value ?? ""}
        disabled={props.disabled}
        autoComplete="off"
        autoFocus={props.autoFocus}
        onChange={e => props.onChange(e.target.value)}
        onBlur={props.onBlur && (e => props.onBlur(e.target.value))}
      />
      {props.tooltip && (
        <>
          <Tooltip title={props.tooltip} open={tooltipOpen}>
            <div
              className={styles.question}
              onClick={() => setTooltipOpen(true)}
            >
              ?
            </div>
          </Tooltip>
          {tooltipOpen && (
            <div
              className={styles.overlay}
              onClick={() => setTooltipOpen(false)}
            ></div>
          )}
        </>
      )}
    </div>
  );
}
