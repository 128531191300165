import { HttpCode } from "../http-code";

type BodyData = object | Blob | FormData;
type SimpleTypes = string | number | boolean;
type QueryData = object | Record<string, SimpleTypes | Array<SimpleTypes>>;

export interface IConditionalUpdateRequest {
  updatedAt: Date;
}

export enum ConcurrencyStrategy {
  LastUpdateWins = "LastUpdateWins",
  ConditionalUpdate = "ConditionalUpdate"
}

export enum HttpMethod {
  Get = "GET",
  Post = "POST",
  Patch = "PATCH",
  Put = "PUT",
  Delete = "DELETE"
};

export enum ApiVersion {
  V1_0 = "1.0",
  V1_1 = "1.1",
};

export enum ApiErrorCode {
  FolderAlreadyExists = "FolderAlreadyExists",
  NameAlreadyExists = "NameAlreadyExists"
}

export interface ApiErrorData {
  err: string;
  subCode?: ApiErrorCode;
}
export class ApiException<TData = any> extends Error {
  constructor(
    message: string = "Something went wrong.",
    readonly status?: number,
    readonly data?: TData
  ) {
    super(message);
  }
}

export type FetchInterceptor = (input: RequestInfo, init?: RequestInit) => (Promise<Response> | false);

export class ApiService {

  private fetchInterceptors: FetchInterceptor[] = [];

  constructor(
    private readonly apiURL: string, 
    private readonly urlPrefix?: string, 
    private readonly customHeaders?: Record<string, string>) {}

  addFetchInterceptor(fetchInterceptor: FetchInterceptor): void {
    this.fetchInterceptors.push(fetchInterceptor);
  }

  removeFetchInterceptor(fetchInterceptor: FetchInterceptor): void {
    this.fetchInterceptors.push(fetchInterceptor);
  }

  get<TResult = void>(url?: string | number, query?: QueryData, version?: ApiVersion) {
    return this.send<TResult>(
      this.appendQuery(url, query),
      this.createRequestInit(HttpMethod.Get, null, this.getVersionHeader(version))
    );
  }
  post<TResult = void>(url?: string | number, data?: BodyData, concurrencyStrategy?: ConcurrencyStrategy, updatedAt?: Date) {
    return this.send<TResult>(
      url, 
      this.createRequestInit(HttpMethod.Post, data, this.createConditionalUpdateHeader(concurrencyStrategy, updatedAt)));
  }
  patch<TResult = void>(url?: string | number, data?: BodyData, concurrencyStrategy?: ConcurrencyStrategy, updatedAt?: Date) {
    return this.send<TResult>(
      url, 
      this.createRequestInit(HttpMethod.Patch, data, this.createConditionalUpdateHeader(concurrencyStrategy, updatedAt)));
  }
  put<TResult = void>(url?: string | number, data?: BodyData, concurrencyStrategy?: ConcurrencyStrategy, updatedAt?: Date) {
    return this.send<TResult>(
      url, 
      this.createRequestInit(HttpMethod.Put, data, this.createConditionalUpdateHeader(concurrencyStrategy, updatedAt))
    );
  }
  putConditionally<TResult = void>(url: string | number, data: IConditionalUpdateRequest) {
    const modifiedData = {...data};
    delete modifiedData.updatedAt;

    return this.put<TResult>(url, modifiedData, ConcurrencyStrategy.ConditionalUpdate, data.updatedAt);
  }
  postConditionally<TResult = void>(url: string | number, data: IConditionalUpdateRequest) {
    const modifiedData = {...data};
    delete modifiedData.updatedAt;

    return this.post<TResult>(url, modifiedData, ConcurrencyStrategy.ConditionalUpdate, data.updatedAt);
  }
  delete<TResult = void>(url?: string | number, query?: QueryData, data?: object, concurrencyStrategy?: ConcurrencyStrategy, updatedAt?: Date) {
    return this.send<TResult>(
      this.appendQuery(url, query),
      this.createRequestInit(HttpMethod.Delete, data, this.createConditionalUpdateHeader(concurrencyStrategy, updatedAt))
    );
  }

  getFullUrl(url?: string | number, query?: QueryData): string {
    let fullUrl = [this.apiURL, this.urlPrefix, this.ensureString(url)].filter((x) => !!x).join("/");
    if (query) {
      fullUrl = this.appendQuery(fullUrl, query)
    }
    return fullUrl;
  }

  upload<T>(url: string, files: File[], onProgress?: (percentage: number, loaded: number, total: number) => void, contentType?: boolean): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const formdata = new FormData();
      const request = new XMLHttpRequest();
      
      files.forEach((file, index) => {
        formdata.append(`File${index + 1}`, file);
      });

      if (onProgress) {
        request.upload.addEventListener("progress", (e) => onProgress((e.loaded / e.total) * 100, e.loaded, e.total), false);
      }

      request.addEventListener("load", (e) => {
        if (request.status === HttpCode.SUCCESS) {
          resolve(request.response as T)
        }
        else {
          reject(new ApiException(`File upload error: ${request.statusText}`, request.status, e))
        }
      }, false);
      request.addEventListener("error", (e) => reject(new ApiException(`File upload error: ${request.statusText}`, request.status, e)), false);
      request.addEventListener("abort", (e) => reject(new ApiException("File uploaded aborted")), false);
      request.responseType = "json";
      request.open(HttpMethod.Post, this.getFullUrl(url), true);
      if (contentType !== false) {
        request.setRequestHeader("Content-Type","multipart/form-data");
      }
      if (this.customHeaders) {
        Object.keys(this.customHeaders).forEach((key) => {
          request.setRequestHeader(key, this.customHeaders[key]);
        })
      }
      request.send(formdata);  
    });
  }

  private send<TResult>(
    url?: string | number,
    requestInit?: RequestInit
  ): Promise<TResult> {
    let fullUrl = this.getFullUrl(url);

    const iterceptorResult: (Promise<Response> | false) = this.fetchInterceptors.reduce((previousResult, interceptor) => {
      return previousResult || interceptor(fullUrl, requestInit);
    }, false);

    return (iterceptorResult || fetch(fullUrl, requestInit)).then((response) => {
      const contentType = response.headers.get("Content-Type");
      let dataPromise;
      if (contentType.includes("application/json;")) {
        dataPromise = response.json();
      } else if (contentType.includes("text/plain;")) {
        dataPromise = response.text();
      } else {
        dataPromise = response.blob();
      }
      return dataPromise.then((data) => {
        if (response.ok) {
          return data;
        } else {
          throw new ApiException(data.err || data, response.status, data);
        }
      });
    });
  }

  private createRequestInit(method: HttpMethod, bodyData?: BodyData, customHeaders?: Record<string, string>): RequestInit {
    let body: BodyInit = null,
      contentType = "application/json";
    if (bodyData) {
      if (bodyData instanceof FormData || bodyData instanceof Blob) {
        body = bodyData;
        contentType = "";
      } else {
        body = JSON.stringify(bodyData, this.onStringify);
      }
    }
    return {
      method,
      credentials: "include",
      headers: {
        "Content-Type": contentType,
        ...this.customHeaders,
        ...customHeaders
      },
      body,
    };
  }

  private appendQuery(url?: string | number, query?: QueryData) {
    let result = this.ensureString(url);
    if (query) {
      const urlSearchParams = new URLSearchParams();
      Object.entries(query).forEach(([paramName, paramValue]) => {
        if (paramValue != null) {
          switch (typeof paramValue) {
            case "string":
            case "number":
              urlSearchParams.append(paramName, String(paramValue));
              break;
            default:
              urlSearchParams.append(
                paramName,
                JSON.stringify(paramValue, this.onStringify)
              );
          }
        }
      });
      if (urlSearchParams.toString()) {
        result += `?${urlSearchParams}`;
      }
    }
    return result;
  }

  private ensureString(value: string | number) {
    return value == null ? "" : String(value);
  }

  private onStringify(key: string, value: any) {
    if (typeof value === "object" && value instanceof Date) {
      return value.toISOString();
    }
    return value;
  }

  private createConditionalUpdateHeader(concurrencyStrategy?: ConcurrencyStrategy, updatedAt?: Date): Record<string, string> {
    if (concurrencyStrategy === ConcurrencyStrategy.ConditionalUpdate) {
      if (updatedAt) {
        return {"If-Unmodified-Since": updatedAt.toUTCString()};
      }
      else {
        throw new Error(`'updatedAt' should be specified for conditional update request`);
      }
    }
  }

  private getVersionHeader(version: ApiVersion) {
    return version && { "accept": `application/json;mv=${version}` };
  }
}
