import { DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME, DELETE_CONFIRMATION_DIALOG_NAME, REPLACE_STORAGE_CONFIRMATION, STORAGE_POOL_ENTITY_NAME } from './../actions/storage-pool';
import { IStoragePoolsRequestParams, IStoragePoolShort, StoragePoolSortField } from './../../api/entities/storage-pool';
import { createEntityReducer, getEntityDefaultState, IEntityState } from './entity';
import { AnyAction } from 'redux';
import { IDialogState } from '.';
import { createDialogReducer, DIALOG_DEFAULT_STATE } from './dialog';
import { SortDirection } from '../../api/entities/page';
import { IStorageNode } from '../../api/entities/storage-node';
import { IDeleteBlockedConfirmationData } from '../../components/storage-pools/dialogs/delete-blocked-confirmation/delete-blocked-confirmation';

export interface IStoragePoolState extends IEntityState<IStoragePoolShort, StoragePoolSortField, IStoragePoolsRequestParams> {
  [DELETE_CONFIRMATION_DIALOG_NAME]: IDialogState<IStoragePoolShort>;
  [DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME]: IDialogState<IDeleteBlockedConfirmationData>;
  [REPLACE_STORAGE_CONFIRMATION]: IDialogState<IStorageNode>;
}

export const SHARING_INSTANCE_DEFAULT_STATE: IStoragePoolState = {
  [DELETE_CONFIRMATION_DIALOG_NAME]: DIALOG_DEFAULT_STATE,
  [REPLACE_STORAGE_CONFIRMATION]: DIALOG_DEFAULT_STATE,
  [DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME]: DIALOG_DEFAULT_STATE,
  ...getEntityDefaultState(StoragePoolSortField.Name, SortDirection.Ascending)
};

const entityReducer = createEntityReducer(STORAGE_POOL_ENTITY_NAME);
const deleteConfirmationReducer = createDialogReducer(STORAGE_POOL_ENTITY_NAME, DELETE_CONFIRMATION_DIALOG_NAME);
const deleteBlockedConfirmationReducer = createDialogReducer(STORAGE_POOL_ENTITY_NAME, DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME);
const replaceStorageConfirmationReducer = createDialogReducer(STORAGE_POOL_ENTITY_NAME, REPLACE_STORAGE_CONFIRMATION);

export function storagePool(
  state: IStoragePoolState = SHARING_INSTANCE_DEFAULT_STATE,
  action: AnyAction
): IStoragePoolState {
  state = entityReducer(state, action);
  state = deleteConfirmationReducer(state, action);
  state = deleteBlockedConfirmationReducer(state, action);
  state = replaceStorageConfirmationReducer(state, action);
  return state;
}
