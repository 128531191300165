import { store } from "..";
import { userService } from "../api/services/user-service";
import { catchException, commonActions } from "../redux/actions/common";

const imageExtentions = ["png", "jpg", "jpeg", "bmp", "gif", "svg", "psd", "raw"]

export class FileUtils {

  getFileExtension(fileName: string): string {
    const dotPosition = fileName.lastIndexOf(".");
    let fileExtension = "";
    if (dotPosition) {
      fileExtension = fileName.substring(dotPosition + 1);
    }
    return fileExtension;
  }

  isImage(fileExtension: string): boolean {
    return imageExtentions.indexOf(fileExtension) >= 0;
  }

  downloadFile(url: string): void {
    store.dispatch(commonActions.startLoading());
    userService.getCurrentUser().catch(catchException()).then((user) => {
      store.dispatch(commonActions.stopLoading());
      if (user) {
        window.location.href = url;
      }
    })
  }
}

export const fileUtils = new FileUtils();
