import { IPage } from '../entities/page';
import { IAddStorageNodeRequest, IStorageNode, IStorageNodeTestConnectionRequest, IStorageNodeTestConnectionResponse, IUpdateStorageNodeRequest, IStorageNodeCompact, StorageType, StorageProvider } from './../entities/storage-node';
import { DataService } from "./data-service";

class StorageNodeService extends DataService {
    constructor() {
        super("storagenode");
    }
    getStorageNode(storageNodeId: number) {
        return this.api.get<IStorageNode>(storageNodeId);
    }

    getCompactList(storageType?: StorageType): Promise<IStorageNodeCompact[]> {
        return this.api.get<IStorageNodeCompact[]>(`compact`, { storageType });
    }

    getStorageNodes() {
        return this.api.get<IPage<IStorageNode>>();
    }

    updateStorageNode(updateRequest: IUpdateStorageNodeRequest) {
        return this.api.put<IStorageNode>(updateRequest.id, this.encodeEndpointUrl(updateRequest));
    }

    addStorageNode(addRequest: IAddStorageNodeRequest) {
        return this.api.post<IStorageNode>("", this.encodeEndpointUrl(addRequest));
    }

    deleteStorageNode(storageNodeId: number) {
        return this.api.delete(storageNodeId);
    }

    testConnection(request: IStorageNodeTestConnectionRequest) {
        return this.api.post<IStorageNodeTestConnectionResponse>("testConnection", this.encodeEndpointUrl(request));
    }

    nameValidator(name: string, storageProvider: StorageProvider) {
        return this.api.get<boolean>(`nameValidator/${encodeURIComponent(name)}/${storageProvider}`);
    }

    private encodeEndpointUrl<TRequest extends IStorageNodeTestConnectionRequest | IAddStorageNodeRequest | IUpdateStorageNodeRequest>
        (request: TRequest): TRequest {
        return {...request, endpointURL: this.encodeUri(request.endpointURL)}
    }
}

export const storageNodeService = new StorageNodeService();