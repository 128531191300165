import React, { useState, useEffect, ReactNode } from "react";
import styles from "./input-with-units.module.scss";
import IOption from "../../common/option";
import ButtonGroup from "../../button-group";
import clsx from "clsx";
import IComponentProps from "../../common/component-props";
import TooltipInput from "../tooltip-input/tooltip-input";
interface IInputWithUnitsProps extends IComponentProps {
  autoFocus?: boolean;
  value: number;
  units: IOption[];
  selectedUnit: any;
  precision?: number;
  tooltip?: ReactNode;
  testId: string;

  onChange: (value: number) => void;
  onUnitChange: (value: any) => void;
}

export default function InputWithUnits(props: IInputWithUnitsProps) {
  const [inputValue, setInputValue] = useState(formatValue(props.value));

  function formatValue(value: number) {
    return value || value === 0 ? value.toFixed(props.precision) : "";
  }

  function onInputChange(inputValue: string) {
    const value = inputValue ? Number(inputValue) : null;
    setInputValue(inputValue);
    if (props.value !== value) {
      props.onChange(value);
    }
  }

  function formatInputValue() {
    setInputValue(formatValue(props.value));
  }

  useEffect(() => {
    const value = inputValue ? Number(inputValue) : null;
    if (props.value !== value) {
      setInputValue(formatValue(props.value));
    }
    // eslint-disable-next-line
  }, [props.value]);

  useEffect(() => {
    formatInputValue();
    // eslint-disable-next-line
  }, [props.precision]);

  return (
    <div
      className={clsx(styles.root, props.className)}
      data-testid={props.testId}
      style={props.style}
    >
      <TooltipInput
        autoFocus={props.autoFocus}
        value={inputValue}
        type="number"
        className={styles.input}
        disabled={props.disabled}
        tooltip={props.tooltip}
        onChange={onInputChange}
        onBlur={formatInputValue}
      />
      <ButtonGroup
        value={props.selectedUnit}
        options={props.units}
        colors="secondary"
        disabled={props.disabled}
        onChange={props.onUnitChange}
      />
    </div>
  );
}
