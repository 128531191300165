import React from "react";
import { IStorageNode, StorageNodeStatus } from "../../../../api/entities/storage-node";
import { IFormField } from "../../../../utils/form-utils";
import { storageUtils } from "../../../../utils/storage-utils";
import Button from "../../../shared-ui/button";
import Checkbox from "../../../shared-ui/input/checkbox";
import styles from "./storage-nodes-section.module.scss";
import tableStyles from "../../../shared-ui/table/table.module.scss";
import { IStorageNodeTypes } from "../storage-type-section/storage-type-section";
import { MoreOption } from "./moreoption";
import { StoragePoolFormMode } from "../../storage-pool-form/storage-pool-form";
import { storageNodeService } from "../../../../api/services/storage-node-service";
import FormSection from "../../../shared-ui/form/form-section/form-section";
import { IColumnDefinition } from "../../../shared-ui/table/table";
import { createRemoveColumn } from "../../../shared-ui/compact-table/columns/remove-column";
import { CompactTable } from "../../../shared-ui/compact-table/compact-table";
import { CONFIG } from "../../../../config/config";
import { useApi } from "../../../../utils/hooks/use-api";

export interface IStoragePoolNode {
  node: IStorageNode;
  types: IStorageNodeTypes;
}

export interface IStorageNodesSectionProps {

  mode: StoragePoolFormMode;
  storagePoolNodes: IFormField<IStoragePoolNode[]>;
  onEditStorageNode: (node: IStoragePoolNode) => void;
  onReplaceStorageNode?: (node: IStoragePoolNode) => void;
  onAddStorageNode: () => void;
}

export function validateStoragePoolNodes(nodes: IStoragePoolNode[]) {
  if ( 
    nodes.filter(n => n.types.storeContent).length < CONFIG.minStoragesCount || 
    nodes.filter(n => n.types.storeKey).length < CONFIG.minStoragesCount || 
    nodes.filter(n => n.types.storeMetadata).length < CONFIG.minStoragesCount
  ) {
    return `Myota requires at least ${CONFIG.minStoragesCount} storage nodes configured for content, key, and metadata.`;
  }

  if ( 
    nodes.filter(n => n.types.storeContent).length > CONFIG.maxStoragesCount || 
    nodes.filter(n => n.types.storeKey).length > CONFIG.maxStoragesCount || 
    nodes.filter(n => n.types.storeMetadata).length > CONFIG.maxStoragesCount
  ) {
    return `Maximum allowed storages for content, key, and metadata is ${CONFIG.maxStoragesCount}.`;
  }

  const nodeWithoutType = nodes.find(n => !n.types.storeContent && !n.types.storeKey && !n.types.storeMetadata)
  if (nodeWithoutType) {
    return `Please select Storage Type for node '${nodeWithoutType.node.name}'`;
  }
  return null;
}

export const StorageNodesSection = (props: IStorageNodesSectionProps) => {
  const [api, isLoading] = useApi();
  const nodesToMigrateTo = props.storagePoolNodes.value.filter(n => n.node.status === StorageNodeStatus.MigrationInProgress);
  const nodesToMigrateFrom = props.storagePoolNodes.value.filter(
    n => n.node.replacedByStorageNodeID &&
    nodesToMigrateTo.some(migrateTo => migrateTo.node.id === n.node.replacedByStorageNodeID));

  function deleteStorageNode(poolNode: IStoragePoolNode) {
    api(storageNodeService.deleteStorageNode(poolNode.node.id)).then(() => {
      props.storagePoolNodes.onChange(props.storagePoolNodes.value.filter(n => n !== poolNode));
    });
  }

  const columns:  IColumnDefinition<IStoragePoolNode, null>[] = [
    {
      testId: "col-name",
      className: styles["col-name"],
      label: "Name",
      formatter: (poolNode) => (
        <>
          {poolNode.node.status === StorageNodeStatus.MigrationInProgress && (
            <>
              <div className={styles["migrate-from"]}>
                <span className={styles["migrate-from-icon"]}></span>
                {nodesToMigrateFrom.find(n => n.node.replacedByStorageNodeID === poolNode.node.id).node.name}
              </div>
              <div className={styles["migrate-to"]}>
                <span className={styles["migrate-to-icon"]}></span>
                {poolNode.node.name}
              </div>
            </>
          )}
          {poolNode.node.status !== StorageNodeStatus.MigrationInProgress && (
            <Button testId="name-button" className={styles["node-link"]} colors="link" onClick={() => props.onEditStorageNode(poolNode)}>
              {poolNode.node.name}
            </Button>
          )}
        </>
      )
    },
    {
      testId: "col-provider",
      className: styles["col-provider"],
      label: "Provider",
      formatter: (poolNode) => (
        <>
          {poolNode.node.status === StorageNodeStatus.MigrationInProgress && (
            <>
              <div className={styles["migrate-from"]}>
                {storageUtils.getStorageProviderName(nodesToMigrateFrom.find(n => n.node.replacedByStorageNodeID === poolNode.node.id).node.StorageTypeID)}
              </div>
              <div className={styles["migrate-to"]}>
                {storageUtils.getStorageProviderName(poolNode.node.StorageTypeID)}
              </div>
            </>
          )}
          {poolNode.node.status !== StorageNodeStatus.MigrationInProgress && storageUtils.getStorageProviderName(poolNode.node.StorageTypeID)}
        </>
      )
    }
  ];

  if (props.mode === StoragePoolFormMode.Create) {
    columns.push(
    {
      testId: "col-content",
      className: styles["col-content"],
      label: "Content",
      formatter: poolNode => 
        <Checkbox
          testId="content-checkbox"
          checked={poolNode.types.storeContent}
          onChange={(value) => {
            poolNode.types.storeContent = value;
            props.storagePoolNodes.onChange([...props.storagePoolNodes.value]);
          }}
        />
    }, 
    {
      testId: "col-key",
      className: styles["col-key"],
      label: "Key",
      formatter: poolNode => 
        <Checkbox
          testId="key-checkbox"
          checked={poolNode.types.storeKey}
          onChange={(value) => {
            poolNode.types.storeKey = value;
            props.storagePoolNodes.onChange([...props.storagePoolNodes.value]);
          }}
        />
    },
    {
      testId: "col-metadata",
      className: styles["col-metadata"],
      label: "Metadata",
      formatter: poolNode => 
      <Checkbox
        testId="metadata-checkbox"
        checked={poolNode.types.storeMetadata}
        onChange={(value) => {
          poolNode.types.storeMetadata = value;
          props.storagePoolNodes.onChange([...props.storagePoolNodes.value]);
        }}
      />
    },
    createRemoveColumn<IStoragePoolNode>(deleteStorageNode))
  }

  if (props.mode === StoragePoolFormMode.Edit) {
    columns.push({
      testId: "col-manage",
      className: tableStyles["col-manage"],
      label: "Manage",
      formatter: (poolNode) => (
        <>
          {poolNode.node.status === StorageNodeStatus.MigrationInProgress && (
            <div className={styles["migration-in-progress"]}>Replace<br/> in progress</div>
          )}
          {poolNode.node.status !== StorageNodeStatus.MigrationInProgress && (
            <MoreOption 
              storagePoolNode={poolNode}
              onEditStorageNode={props.onEditStorageNode}
              onReplace={props.onReplaceStorageNode}
              canReplaceNode={!nodesToMigrateTo.length}
            />
          )}        
        </>
      )
    });
  }


  return (   
    <FormSection 
      testId="add-storage-nodes-section" 
      title="Add Storage Nodes"
      description={`Myota requires at least ${CONFIG.minStoragesCount} storage nodes.`}
    >
      <CompactTable
        testId="storage-pool-nodes-table"
        className={styles.table}
        loading={isLoading}
        columns={columns}
        rows={props.storagePoolNodes.value.filter(n => nodesToMigrateFrom.indexOf(n) < 0)}
        rowId={(poolNode) => poolNode.node.id}
        onAddClick={props.mode === StoragePoolFormMode.Create ? props.onAddStorageNode : null}
      />
    </FormSection>
  );
};