import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { catchException, commonActions } from "../../../../redux/actions/common";
import { IAppState } from "../../../../redux/reducers";
import DetailsPanel from "../../../shared-ui/details-panel/details-panel";
import styles from "./storage-node-panel.module.scss";
import { storageNodeService } from "../../../../api/services/storage-node-service";
import { AllStorageProps, IStorageNode, IStorageNodeTestConnectionRequest, S3CompatibleVendor, StorageProvider } from "../../../../api/entities/storage-node";
import { FormPanel } from "../../../shared-ui/form/form-panel/form-panel";
import { IStorageNodeForm, IStorageNodeFormValue, StorageNodeSection } from "../../sections/storage-node-section/storage-node-section";
import { IStorageNodeTypes, IStorageTypeForm, IStorageTypeFormValue, StorageTypeSection } from "../../sections/storage-type-section/storage-type-section";
import { formUtils, useField } from "../../../../utils/form-utils";
import { validators } from "../../../../utils/validators";
import { storageUtils } from "../../../../utils/storage-utils";
import { IStoragePoolNode } from "../../sections/storage-nodes-section/storage-nodes-section";
import { ReplaceStorageConfirmation } from "../../dialogs/replace-storage-confirmation/replace-storage-confirmation";
import { storagePoolActions } from "../../../../redux/actions/storage-pool";
import { useApi } from "../../../../utils/hooks/use-api";

function mapDispatchToProps(dispatch: ThunkDispatch<IAppState, void, Action>) {
  return {
    showSnackBar: (message: string) => {
      return dispatch(commonActions.showSnackBar(true, message));      
    },
    openReplaceConfirmation: (storageNode: IStorageNode) => {
      return dispatch(storagePoolActions.replaceStorageConfirmation.open(storageNode));      
    },
    closeReplaceConfirmation: () => {
      return dispatch(storagePoolActions.replaceStorageConfirmation.close());      
    }
  };
}

export enum StorageNodePanelMode {
  Add = 1,
  FullEdit = 2,
  LimitedEdit = 3,
  Replacement = 4
} 

export interface IStorageNodePanelProps extends 
  ReturnType<typeof mapDispatchToProps> {
    storagePoolNode?: IStoragePoolNode;
    mode: StorageNodePanelMode;
    onClose: () => void;
    onNodeSaved: (node: IStorageNode, storageNodeTypes: IStorageNodeTypes) => void;
}

const modeToTitleMap = {
  [StorageNodePanelMode.Add]: "Add a Storage Node",
  [StorageNodePanelMode.FullEdit]: "Manage Storage Node",
  [StorageNodePanelMode.LimitedEdit]: "Manage Storage Node",
  [StorageNodePanelMode.Replacement]: "Replace Storage Node",
};

const modeToActionTitleMap = {
  [StorageNodePanelMode.Add]: "Add Storage Node",
  [StorageNodePanelMode.FullEdit]: "Done",
  [StorageNodePanelMode.LimitedEdit]: "Done",
  [StorageNodePanelMode.Replacement]: "Replace Storage Node",
};

const formDefaultValue: IFormValue = {
  type: null,
  name: "",
  region: "",
  isCustomRegion: false,
  vendor: S3CompatibleVendor.MinIO,
  endpointURL: "",
  bucket: "",
  account_name: "",
  container: "",
  arn: "",
  project_id: "",
  key: "",
  secret: "",
  client_email: "",
  storeContent: true,
  storeMetadata: true,
  storeKey: true
};

interface IForm extends IStorageNodeForm, IStorageTypeForm {};
interface IFormValue extends IStorageNodeFormValue, IStorageTypeFormValue {};

export const StorageNodePanel = connect(null, mapDispatchToProps)((props: IStorageNodePanelProps) => {
  const { mode, storagePoolNode } = props;
  const [api, isLoading] = useApi();
  const [testConnectionResult, setTestConnectionResult] = useState<boolean>(null);
  const [isTestConnectionRequired, setIsTestConnectionRequired] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);   
  const open = !!storagePoolNode || mode === StorageNodePanelMode.Add;

  const form: IForm = {
    type: useField<StorageProvider>(formDefaultValue.type, [validators.required()]),
    name: useField<string>(formDefaultValue.name, [validators.required()]),
    region: useField<string>(formDefaultValue.region, [validators.required()]),
    isCustomRegion: useField<boolean>(formDefaultValue.isCustomRegion),
    vendor: useField<S3CompatibleVendor>(formDefaultValue.vendor),
    endpointURL: useField<string>(formDefaultValue.vendor, [validators.required()]),
    bucket: useField<string>(formDefaultValue.bucket, [validators.required()], [{ asyncValidator: validateBucketName }], true),
    account_name: useField<string>(formDefaultValue.account_name, [validators.required()]),
    container: useField<string>(formDefaultValue.container, [validators.required()]),
    arn: useField<string>(formDefaultValue.arn, [validators.required()]),
    project_id: useField<string>(formDefaultValue.project_id, [validators.required()]),
    key: useField<string>(formDefaultValue.key, [validators.required()]),
    secret: useField<string>(formDefaultValue.secret, [(value) => isTestConnectionRequired ? validators.required()(value) : null]),
    client_email: useField<string>(formDefaultValue.client_email, [validators.required()]),
    storeContent: useField<boolean>(true),
    storeMetadata: useField<boolean>(true),
    storeKey: useField<boolean>(true),
  };
  const testConnectionRequest = getTestConnectionRequest();

  useEffect(() => {
    setErrorMessage(null);

    const connectionSensetiveFields = {...form};
    delete connectionSensetiveFields.name;
    delete connectionSensetiveFields.storeContent;
    delete connectionSensetiveFields.storeKey;
    delete connectionSensetiveFields.storeMetadata;

    const isConnectionParamsChanged = formUtils.isChanged(connectionSensetiveFields);
    if (isConnectionParamsChanged) {
      setIsTestConnectionRequired(true);
      setTestConnectionResult(null);
    }

    // eslint-disable-next-line
  }, Object.values(formUtils.getFormValue(form, true)));

  useEffect(() => {
    if (open) {
      
      if (storagePoolNode) {
        setForm(storagePoolNode.node, storagePoolNode.types);
        formUtils.setIsDisabled(form, mode === StorageNodePanelMode.LimitedEdit);
        setIsTestConnectionRequired(false);
        setTestConnectionResult(null);
        if (mode === StorageNodePanelMode.LimitedEdit) {
          form.name.setIsDisabled(false);
          form.client_email.setIsDisabled(false);
          form.key.setIsDisabled(false);
          form.arn.setIsDisabled(false);
          form.secret.setIsDisabled(false);
        }
      }
      if (mode === StorageNodePanelMode.Add || mode === StorageNodePanelMode.Replacement) {
        formUtils.setFormValue<IFormValue>(form, formDefaultValue);
        formUtils.setIsDisabled(form, false);
        setIsTestConnectionRequired(true);
        setTestConnectionResult(null);
      }
    }

    // eslint-disable-next-line
  }, [open, storagePoolNode]);

  function getTestConnectionRequest(): IStorageNodeTestConnectionRequest {
    const formValue = storageUtils.cleanNotApplicableFields(formUtils.getFormValue<IStorageNodeFormValue>(form));
    delete formValue.name;
    return formValue;
  }

  function validateBucketName(bucketName: string): Promise<string> {
    if (mode === StorageNodePanelMode.FullEdit) {
      const nodeProps = JSON.parse(props.storagePoolNode.node.props) as AllStorageProps;
      if (nodeProps.bucket === bucketName) {
        return Promise.resolve(null);
      }
    }
    return storageNodeService.nameValidator(bucketName, form.type.value)
      .catch(catchException())
      .then((success) => success ? null : "Bucket name should be unique");
  }

  function setForm(storageNode: IStorageNode, storageTypes?: IStorageNodeTypes) {
    const nodeProps = JSON.parse(storageNode.props) as AllStorageProps;
    const formValue: IFormValue = {
      name: storageNode.name,
      arn: nodeProps.roleArn,
      type: storageNode.StorageTypeID,
      secret: "",
      storeContent: storageTypes ? storageTypes.storeContent : !!storageNode.ContentStoragePool.length,
      storeKey: storageTypes ? storageTypes.storeKey : !!storageNode.KeyStoragePool.length,
      storeMetadata: storageTypes ? storageTypes.storeMetadata : !!storageNode.MetaStoragePool.length,
      isCustomRegion: storageUtils.isCustomRegion(
        storageNode.StorageTypeID,
        nodeProps.region
      ),
      ...nodeProps
    };
    formUtils.setFormValue(form, formValue);
  }

  function addNode(formValue: IFormValue) {
    const replaceOldNode = mode === StorageNodePanelMode.FullEdit || mode === StorageNodePanelMode.Replacement;
    return api(storageNodeService.addStorageNode({
      ...storageUtils.cleanNotApplicableFields(formValue),
      oldStorageNodeId: replaceOldNode ? storagePoolNode.node.id : null, 
      isReplace: replaceOldNode
    })).then((savedNode) => {
      if (savedNode) {
        props.onNodeSaved(savedNode,
          { 
            storeContent: formValue.storeContent, 
            storeKey: formValue.storeKey, 
            storeMetadata: formValue.storeMetadata, 
          }
        );
        props.onClose();
      }
    });
  }

  function updateNode(formValue: IFormValue) {
    return api(storageNodeService.updateStorageNode({
      ...storageUtils.cleanNotApplicableFields(formValue),
      id: storagePoolNode.node.id
    })).then((savedNode) => {
      if (savedNode) {
        props.onNodeSaved(savedNode, canEditStorageType() ?  { 
          storeContent: formValue.storeContent, 
          storeKey: formValue.storeKey, 
          storeMetadata: formValue.storeMetadata, 
        } : storagePoolNode.types);
        props.onClose();
      }
    });
  }

  function canEditStorageType() {
    return mode === StorageNodePanelMode.Add || mode === StorageNodePanelMode.FullEdit;
  }

  function handleReplaceConfirmed() {
    addNode(formUtils.getFormValue<IFormValue>(form)).then(() => {
      props.closeReplaceConfirmation();
    });
  }

  function save() {
    if (!formUtils.isChanged(form)) {
      props.onClose();
      return;
    }

    if (!form.type.value) {
      setErrorMessage("Please select provider");
      form.type.validate(form.type.value);
      return;
    }
    if (isTestConnectionRequired && !testConnectionResult) {
      setErrorMessage("Please test connection");
      return;
    }
    if (canEditStorageType() && !form.storeContent.value && !form.storeKey && !form.storeMetadata) {
      setErrorMessage("Please Select Storage Type");
      return;
    }

    formUtils.validateAll(form).then((isValid) => {
      if (isValid) {
        const formValue = formUtils.getFormValue<IFormValue>(form);
        if (mode === StorageNodePanelMode.Replacement) {
          props.openReplaceConfirmation(storagePoolNode.node);
        }
        else if (mode === StorageNodePanelMode.LimitedEdit || (mode === StorageNodePanelMode.FullEdit && !isTestConnectionRequired)) {
          updateNode(formValue);
        }
        else if (mode === StorageNodePanelMode.Add || mode === StorageNodePanelMode.FullEdit) {
          addNode(formValue);
        }
      }
    });
  }

  function testConnection(testConnectionRequest: IStorageNodeTestConnectionRequest) {
    api(storageNodeService.testConnection(testConnectionRequest)).then((result) => {
      setTestConnectionResult(result.isConnectionSuccessfull);
      if (result.isConnectionSuccessfull) {
        setErrorMessage(null);
      }
      else {
        props.showSnackBar(result.msg);
      }
    });
  }

  return (
    <>
      <DetailsPanel
        isLoading={isLoading}
        open={open}
        onCloseClick={props.onClose}
        title={modeToTitleMap[mode]}
        className={styles.panel}
      >
        {open && (
          <FormPanel
            okButtonText={modeToActionTitleMap[mode]}
            okButtonClick={save}
            okButtonDisabled={testConnectionResult === false}
            errorMessage={errorMessage}
          >
            <StorageNodeSection
              form={form}
              isTestConnectionSuccess={testConnectionResult}
              testConnectionRequest={testConnectionRequest}
              onTestConnection={testConnection}
            />
            {canEditStorageType() && (
              <StorageTypeSection
                form={form}
              />
            )}
          </FormPanel>
        )}
      </DetailsPanel>
      {mode === StorageNodePanelMode.Replacement && (
        <ReplaceStorageConfirmation onConfirm={handleReplaceConfirmed}/>
      )}
    </>
  );
});
