import React from "react";
import { IFormField } from "../../../../utils/form-utils";
import Checkbox from "../../../shared-ui/input/checkbox";
import SidePanelSection from "../../../shared-ui/side-panel-section/side-panel-section";
import styles from "./storage-type-section.module.scss";

export interface IStorageNodeTypes {
  storeContent: boolean;
  storeMetadata: boolean;
  storeKey: boolean;
}

export interface IStorageTypeForm {
  storeContent: IFormField<boolean>;
  storeMetadata: IFormField<boolean>;
  storeKey: IFormField<boolean>;
}

export interface IStorageTypeFormValue extends IStorageNodeTypes {};

export interface IStorageTypeSectionProps {
  form: IStorageTypeForm;
}

export function StorageTypeSection(props: IStorageTypeSectionProps) {
  return (   
    <SidePanelSection testId="storage-type-section" title="Select Storage Type">
      <div className={styles.types}>
        <div className={styles.type}>
          <Checkbox testId="content" field={props.form.storeContent} label="Content"/>
        </div>
        <div className={styles.type}>
          <Checkbox testId="key" field={props.form.storeKey} label="Key"/>
        </div>
        <div className={styles.type}>
          <Checkbox testId="metadata" field={props.form.storeMetadata} label="Metadata"/>
        </div>
      </div>
    </SidePanelSection>
  );
}