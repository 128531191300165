import { DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME, DELETE_CONFIRMATION_DIALOG_NAME, FOLDER_EXISTS_CONFIRMATION_DIALOG_NAME, LAST_MANAGER_INFO_DIALOG_NAME, SHARING_PROFILE_ENTITY_NAME } from './../actions/sharing-profile';
import { ISharingProfilesRequestParams, ISharingProfileShort, IUserSharingInstances, SharingProfileSortField, ISharingProfile } from './../../api/entities/sharing-profile';
import { IDialogState } from ".";
import { createEntityReducer, getEntityDefaultState, IEntityState } from './entity';
import { createDialogReducer, DIALOG_DEFAULT_STATE } from './dialog';
import { AnyAction } from 'redux';
import { SortDirection } from '../../api/entities/page';

export interface IDeleteBlockedConfirmationData {
  sharingInstancesCount: number;
  sharingProfileId: number;
}
export interface ISharingProfileState  extends IEntityState<ISharingProfileShort, SharingProfileSortField, ISharingProfilesRequestParams> {
  [DELETE_CONFIRMATION_DIALOG_NAME]: IDialogState<ISharingProfileShort[]>;
  [DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME]: IDialogState<IDeleteBlockedConfirmationData>;
  [FOLDER_EXISTS_CONFIRMATION_DIALOG_NAME]: IDialogState<ISharingProfile>;
  [LAST_MANAGER_INFO_DIALOG_NAME]: IDialogState<IUserSharingInstances[]>;
}

export const SHARING_PROFILE_DEFAULT_STATE: ISharingProfileState = {
  [DELETE_CONFIRMATION_DIALOG_NAME]: DIALOG_DEFAULT_STATE,
  [DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME]: DIALOG_DEFAULT_STATE,
  [FOLDER_EXISTS_CONFIRMATION_DIALOG_NAME]: DIALOG_DEFAULT_STATE,
  [LAST_MANAGER_INFO_DIALOG_NAME]: DIALOG_DEFAULT_STATE,
  ...getEntityDefaultState(SharingProfileSortField.ConsoleName, SortDirection.Ascending)
};

const entityReducer = createEntityReducer(SHARING_PROFILE_ENTITY_NAME);
const deleteConfirmationReducer = createDialogReducer(SHARING_PROFILE_ENTITY_NAME, DELETE_CONFIRMATION_DIALOG_NAME);
const deleteBlockedConfirmationReducer = createDialogReducer(SHARING_PROFILE_ENTITY_NAME, DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME);
const folderExistsConfirmationReducer = createDialogReducer(SHARING_PROFILE_ENTITY_NAME, FOLDER_EXISTS_CONFIRMATION_DIALOG_NAME);
const lastManagerInfoReducer = createDialogReducer(SHARING_PROFILE_ENTITY_NAME, LAST_MANAGER_INFO_DIALOG_NAME);

export function sharingProfile(
  state: ISharingProfileState = SHARING_PROFILE_DEFAULT_STATE,
  action: AnyAction
): ISharingProfileState {
  state = entityReducer(state, action);
  state = deleteConfirmationReducer(state, action);
  state = deleteBlockedConfirmationReducer(state, action);
  state = folderExistsConfirmationReducer(state, action);
  state = lastManagerInfoReducer(state, action);
  return state;
}
