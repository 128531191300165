import React, { ReactNode, useState, useEffect } from "react";
import clsx from "clsx";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Checkbox from "../input/checkbox";
import _ from "underscore";
import {
  SortDirection,
  IRequestParams,
} from "../../../api/entities/page";
import {
  IStyleableComponentProps,
  ITestableComponent,
} from "../common/component-props";
import { Paging } from "../paging/paging";
import styles from "./table.module.scss";
import { LocalLoader } from "../loader";
import { IEntity } from "../../../api/entities/id-name";

export type TableSelected<T> = Record<number, T>;
export type SortByHandler<T> = (data: T) => string;
type SortByType<T> = string | SortByHandler<T>;
export type OnRequestParamsChangedHandler<T> = (params: Partial<IRequestParams<T>>) => void;

export interface IColumnDefinition<
  TRowData,
  TSort extends SortByType<TRowData> = SortByType<TRowData>
> {
  groupLabel?: string;
  label?: ReactNode;
  sortKey?: TSort;
  className?: string;
  testId?: string;

  formatter: (data: TRowData, onRowChanged?: (data: TRowData) => void) => ReactNode;
}

export interface ICheckboxColumnDefinition<TRowData> {
  formatter?: (data: TRowData, checked: boolean, onChange: ()=> void ) => ReactNode;
}

interface IColumnGroup<TRowData, TSort extends SortByType<TRowData>> {
  groupLabel: string;
  columns: IColumnDefinition<TRowData, TSort>[];
}

export interface ITableProps<TRowData, TSort extends SortByType<TRowData>>
  extends IStyleableComponentProps,
    ITestableComponent {
  testId: string;
  columns: IColumnDefinition<TRowData, TSort>[];
  rows: TRowData[];
  totalRows?: number;
  hideCheckbox?: boolean;
  checkboxColumn?: ICheckboxColumnDefinition<TRowData>;
  hidePaging?: boolean;
  requestParams: IRequestParams<TSort>;
  selected?: TableSelected<TRowData>;
  canSelectRow?: (data: TRowData) => boolean;
  loading?: boolean;
  responsive?: boolean;
  classes?: {
    row?: (row: TRowData) => string;
  };
  compact?: boolean;
  placeholder?: ReactNode;
  autoHidePaging?: boolean;

  toolbar?: (
    selected: Record<number, TRowData>,
    reset: () => void
  ) => ReactNode;
  onRowClick?: (data: TRowData) => void;
  canClickRow?: (data: TRowData) => boolean;
  onRowChanged?: (data: TRowData) => void;
  onRequestParamsChanged?: OnRequestParamsChangedHandler<TSort>;
  onSelectAll?: () => void;
  onSetSelected?: (
    selected: TableSelected<TRowData>,
    rowData?: TRowData
  ) => void;
}

export default function Table<
  TRowData extends IEntity<number | string>,
  TSort extends SortByType<TRowData>
>(props: ITableProps<TRowData, TSort>) {
  const [selected, setSelected] = useState<TableSelected<TRowData>>({}),
    [isAllSelected, setIsAllSelected] = useState(false);

  const onSortClick = (col: IColumnDefinition<TRowData, TSort>) => {
      if (col.sortKey) {
        const order = props.requestParams.field === col.sortKey && props.requestParams.order === SortDirection.Ascending
          ? SortDirection.Descending
          : SortDirection.Ascending;
        onRequestParamsChanged({ order, field: col.sortKey });
      }
    },
    getColTestId = (col: IColumnDefinition<TRowData, TSort>) => {
      return (
        col.testId ||
        String(col.label || "")
          .replace(" ", "-")
          .toLowerCase()
      );
    },
    getColumnGroups = (): IColumnGroup<TRowData, TSort>[] => {
      const result: IColumnGroup<TRowData, TSort>[] = [];
      for (let colIndex = 0; colIndex < props.columns.length; colIndex++) {
        const column = props.columns[colIndex];
        if (
          result.length &&
          result[result.length - 1].groupLabel === column.groupLabel
        ) {
          result[result.length - 1].columns.push(column);
        } else {
          result.push({
            groupLabel: column.groupLabel,
            columns: [column],
          });
        }
      }
      return result;
    },
    updateSelected = (newSelected: typeof selected, rowData?: TRowData) => {
      setSelected(newSelected);
      if (props.onSetSelected) {
        props.onSetSelected(newSelected, rowData);
      }
    },
    onSelectAllChange = (checked: boolean) => {
      if (checked) {
        if (props.onSelectAll) {
          props.onSelectAll();
        } else {
          const result = _.indexBy(props.rows, r => r.id);
          updateSelected(result);
        }
      } else {
        updateSelected({});
      }
    },
    onRowCheckboxChange = (rowData: TRowData) => {
      const newSelected = { ...selected };
      if (newSelected[rowData.id]) {
        delete newSelected[rowData.id];
      } else {
        newSelected[rowData.id] = rowData;
      }
      updateSelected(newSelected, rowData);
    },
    onRowChanged = (rowData: TRowData) => {
      props.onRowChanged && props.onRowChanged(rowData);
    },
    onRequestParamsChanged = (params: Partial<IRequestParams<TSort>>) => {
      if (props.onRequestParamsChanged) {
        props.onRequestParamsChanged(params);
      }
    },
    isPagingVisisble = () => {
      return !props.hidePaging && (!props.autoHidePaging || (props.totalRows || props.rows.length) > props.requestParams.pageSize);
    };

  useEffect(() => {
    if (props.onSetSelected) {
      props.onSetSelected({});
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!props.rows.length && Object.values(selected).length) {
      updateSelected({});
    }
    // eslint-disable-next-line
  }, [props.rows]);

  useEffect(() => {
    if (props.selected) {
      setSelected(props.selected);
    }
    // eslint-disable-next-line
  }, [props.selected]);

  useEffect(() => {
    const allCount = Object.keys(selected).length;
    let result;
    if (allCount === 0) {
      result = false;
    } else if (allCount === Math.max(props.rows.length, props.totalRows || 0)) {
      result = true;
    } else {
      result = null;
    }
    setIsAllSelected(result);
  }, [props.rows, selected, props.totalRows]);

  return (
    <div
      className={clsx(
        styles.root,
        props.toolbar && styles["root--toolbar"],
        props.compact && styles["root--compact"],
        props.className
      )}
      data-testid={props.testId}
      style={props.style}
    >
      <LocalLoader isLoading={props.loading}>
        <div
          className={clsx(styles.scroll, {
            [styles["scroll--paging"]]: isPagingVisisble(),
          })}
        >
          <table className={styles.table}>
            <thead>
              {props.columns.some(column => column.groupLabel) && (
                <tr>
                  {!props.hideCheckbox && (
                    <th className={styles["group-header--empty"]} />
                  )}
                  {getColumnGroups().map((colGroup, groupIdx) => (
                    <th
                      key={groupIdx}
                      colSpan={colGroup.columns.length}
                      className={
                        colGroup.groupLabel
                          ? styles["group-header"]
                          : styles["group-header--empty"]
                      }
                    >
                      {colGroup.groupLabel}
                    </th>
                  ))}
                </tr>
              )}
              <tr>
                {!props.hideCheckbox && (
                  <th
                    className={clsx(styles["col--checkbox"], {
                      "hidden-on-small-screen": props.responsive,
                    })}
                  >
                    <Checkbox
                      testId="select-all"
                      checked={isAllSelected}
                      onChange={onSelectAllChange}
                    />
                  </th>
                )}
                {props.columns.map((col, idx) => {
                  return (
                    <th
                      className={clsx(styles.th, col.className)}
                      key={idx}
                      data-testid={getColTestId(col)}
                    >
                      <div
                        className={clsx({
                          [styles.sortable]: !!col.sortKey,
                          [styles["sortable--active"]]:
                            col.sortKey && col.sortKey === props.requestParams.field,
                        })}
                        onClick={() => onSortClick(col)}
                      >
                        {col.label}
                        {col.sortKey && (
                          <ArrowUpwardIcon
                            className={clsx(styles["sort-icon"], {
                              [styles["sort-icon--desc"]]:
                                col.sortKey === props.requestParams.field &&
                                props.requestParams.order === SortDirection.Descending,
                            })}
                          />
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {props.rows.map((rowData, rowIdx) => {
                return (
                  <tr
                    className={clsx(
                      styles.row,
                      {
                        pointer: !!props.onRowClick && (!props.canClickRow || props.canClickRow(rowData)),
                      },
                      props.classes?.row && props.classes.row(rowData)
                    )}
                    key={rowData.id || rowIdx}
                    onClick={() =>
                      props.onRowClick && (!props.canClickRow || props.canClickRow(rowData)) && props.onRowClick(rowData)
                    }
                    data-testid={rowData.id}
                  >
                    {!props.hideCheckbox && (
                      <td
                        className={clsx(styles["col--checkbox"], {
                          "hidden-on-small-screen": props.responsive,
                        })}
                      >
                        {(!props.canSelectRow || props.canSelectRow(rowData)) && (
                          <>
                            {props.checkboxColumn && props.checkboxColumn.formatter &&
                              props.checkboxColumn.formatter(rowData, !!selected[rowData.id], () => onRowCheckboxChange(rowData))
                            }
                            {!props.checkboxColumn && (
                              <Checkbox
                                testId="select-checkbox"
                                checked={!!selected[rowData.id]}
                                onChange={v => onRowCheckboxChange(rowData)}
                              />
                            )}
                          </>                 
                        )}
                      </td>
                    )}
                    {props.columns.map((col, idx) => {
                      return (
                        <td
                          className={clsx(styles.td, col.className)}
                          data-testid={getColTestId(col)}
                          key={idx}
                        >
                          {col.formatter(rowData, onRowChanged)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              {props.placeholder && !props.rows.length && (
                <tr>
                  <td colSpan={props.columns.length}>{props.placeholder}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {isPagingVisisble() && (
          <Paging
            compact={props.compact}
            count={props.totalRows || props.rows.length}
            rowsPerPage={props.requestParams.pageSize}
            page={props.requestParams.offset / props.requestParams.pageSize}
            onChangePage={(event, page) => {
              onRequestParamsChanged({ offset: page * props.requestParams.pageSize });
            }}
            onRowsPerPageChange={event => {
              const pageSize = Number(event.target.value);
              onRequestParamsChanged({ pageSize, offset: 0 });
            }}
          />
        )}
        {props.toolbar && props.toolbar(selected, () => updateSelected({}))}
      </LocalLoader>
    </div>
  );
}
