import React, { Component, lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./components/login/login";
import SnackBar from "./components/shared-ui/snackBar/snackBar";
import SuccessPage from "./components/client/success/index";
import LogoutSuccessPage from "./components/client/logout-success/index";
import { ErrorPage } from "./components/client/error/index";
import CognitoPage from "./components/cognito/cognito";
import OnBoarding from "./components/client/onBoarding/onBoarding";
import { LOGIN_ROUTE, COGNITO_ROUTE, LOGOUT_ROUTE, CITRIX_SHARE_FILES_ROUTE } from "./constants/routes";
import RecycleBin from "./components/client/recycleBin/index";
import LogoutPage from "./components/logout/logout-page";
import Loader from "./components/shared-ui/loader";
import { ShareFiles } from "./components/client/addons/citrix-share-file/components/share-files/share-files";

const ProtectedRoutes = lazy(
  () => import("./components/protected-routes/redirector")
);

class App extends Component {
  render() {
    return (
      <>
        <SnackBar />

        <BrowserRouter>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route path={LOGIN_ROUTE} exact>
                <Login />
              </Route>
              <Route path={COGNITO_ROUTE}>
                <CognitoPage />
              </Route>

              <Route path={CITRIX_SHARE_FILES_ROUTE} exact component={ShareFiles} />
              <Route path="/recyclebin" exact component={RecycleBin} />
              <Route path="/welcome" exact component={OnBoarding} />
              <Route path="/success" exact component={SuccessPage} />
              <Route path={LOGOUT_ROUTE} component={LogoutPage} />
              <Route path="/logout-success" exact component={LogoutSuccessPage} />
              <Route path="/error" exact component={ErrorPage} />
              <Route path="/pending" exact>
                <OnBoarding isPending={true} />
              </Route>

              <Route path="/" component={ProtectedRoutes}></Route>
            </Switch>
          </Suspense>
        </BrowserRouter>
      </>
    );
  }
}
export default App;
