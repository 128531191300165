import { ApiService, FetchInterceptor } from "./api-service";
import version from "../../version.json";
import { CONFIG } from "../../config/config";

export enum Scope {
    Admin = "https://cs.myota.cloud/admin"
}

export abstract class DataService {
    protected readonly api: ApiService;

    addFetchInterceptor(fetchInterceptor: FetchInterceptor): void {
        this.api.addFetchInterceptor(fetchInterceptor);
    }
    
    removeFetchInterceptor(fetchInterceptor: FetchInterceptor): void {
        this.api.removeFetchInterceptor(fetchInterceptor);
    }

    protected encodeUri(uri: string): string {
        return window.btoa(uri);
    }

    constructor(urlPrefix?: string) {
        this.api = new ApiService(CONFIG.apiURL, urlPrefix, { myota_agent: `os: aws; version: ${version?.version}`});
    }
}