import { FetchPageSuccessActionType, SetSelectionActionType } from './../actions/entity';
import { DEFAULT_PAGE_SIZE } from './../../constants/common';
import { IEntitiesPageState } from ".";
import { IPage, IPageRequestParams, IRequestParams, SortDirection } from '../../api/entities/page';
import { EntityActionType, FetchPageRequestActionType, formatAction } from '../actions/entity';
import { AnyAction } from 'redux';
import { IEntity } from '../../api/entities/id-name';



export interface IEntityState<TEntity extends IEntity, TSortField, TSearchParams extends IRequestParams<TSortField>> {
  page: IEntitiesPageState<TEntity, TSortField, TSearchParams>;
  selection: { [id: number]: TEntity };
}

export function getEmptyPage<TEntity>(): IPage<TEntity> {
  return {
    data: [],
    count: 0
  }
}

export function getDefaultRequestParams<TSortField>(sortField?: TSortField, order?: SortDirection): IRequestParams<TSortField> {
  return {
    offset: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    field: sortField,
    order: order
  };
}

export function getEntityDefaultState<TEntity extends IEntity, TSortField, TSearchParams extends IRequestParams<TSortField>>
  (sortField?: TSortField, order?: SortDirection) :IEntityState<TEntity, TSortField, TSearchParams> {
  return {
    page: {
      entities: [],
      searchParams: {
        ...getDefaultRequestParams(sortField, order),
      } as any,
      count: 0,
      isLoading: false,
    },
    selection: {}
  }
};

export function createEntityReducer(entityName: string) {
  const fetchPageRequest = formatAction(entityName, EntityActionType.FetchPageRequest);
  const fetchPageSuccess = formatAction(entityName, EntityActionType.FetchPageSuccess);
  const fetchPageFail = formatAction(entityName, EntityActionType.FetchPageFail);
  const setSelection = formatAction(entityName, EntityActionType.SetSelection);

  return function entityReducer<
    TEntity extends IEntity,
    TSortField,
    TPageRequestParams extends IPageRequestParams,
    TState extends  IEntityState<TEntity, TSortField, TPageRequestParams>>(
    state: TState,
    action: AnyAction
  ): TState {
    switch (action.type) {
      case fetchPageRequest:
        const fetchPageRequestAction = action as FetchPageRequestActionType<TPageRequestParams>;
        return {
          ...state,
          page: {
            ...state.page,
            searchParams: {
              ...state.page.searchParams,
              ...fetchPageRequestAction.pageRequestParams
            },
            isLoading: true,
          }
        };
      case fetchPageSuccess:
        const fetchPageSuccessAction = action as FetchPageSuccessActionType<TEntity>;
        return {
          ...state,
          page: {
            ...state.page,
            entities: fetchPageSuccessAction.page.data,
            count: fetchPageSuccessAction.page.count,
            isLoading: false,
          },
        };
      case fetchPageFail:
        return {
          ...state,
          page: {
            ...state.page,
            isLoading: false,
          },
        };
      case setSelection:
        const setSelectionAction = action as SetSelectionActionType<TEntity>;
        return {
          ...state,
          selection: setSelectionAction.selection
        };
      default:
        return state;
    }
  }
}
