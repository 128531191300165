import React, { Component } from "react";
import Loader from "../shared-ui/loader/index";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { IAppState } from "../../redux/reducers";
import { routeUtils } from "../../utils/route-utils";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { usersThunkActions } from "../../redux/actions/users";

interface ILogoutPageProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps>,
    RouteComponentProps {}

class LogoutPage extends Component<ILogoutPageProps> {
  componentDidMount() {
    this.props.logoutCurrentUser().then(() => {
      this.props.history.replace(routeUtils.getRouteToLogoutSuccessPage());
    });
  }

  render() {
    return <Loader />;
  }
}
const mapStateToProps = (state: IAppState) => ({
  user: state.users.currentUser,
});
const mapDispatchToProps = (dispatch: ThunkDispatch<IAppState, void, Action>) => {
  return {
    logoutCurrentUser: () => {
      return dispatch(usersThunkActions.logoutCurrentUser());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LogoutPage));
