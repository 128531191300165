import { IIdName } from "./id-name";
import { IRequestParams } from "./page";

//enum for storage provider
export enum StorageProvider {
  S3 = 1,
  GCS = 2,
  ABS = 3,
  S3Compatible = 4
}

export enum StorageNodeSortField {
  Status = "status",
  Name = "name",
  Content = "content",
  Metadata = "metadata",
  Key = "key",
  Region = "region",
  Provider = "provider",
  Usage = "usage",
}

export enum StorageNodeStatus {
  Inactive = 0,
  Active = 1,
  Failed = 2,
  MigrationInProgress = 3,
}

export enum StorageType {
  Content = 1,
  Key = 2,
  Meta = 3,
}

export enum S3CompatibleVendor {
  MinIO = "MinIO"
}

export interface IStorageNodeCompact extends IIdName {
  types: StorageType[];
}

export interface IStorageNode {
  ContentStoragePool?: any[];
  KeyStoragePool?: any[];
  MetaStoragePool?: any[];
  StorageType: IStorageType;
  StorageTypeID: StorageProvider;
  createdAt: string;
  desc: string;
  id: number;
  name: string;
  props: string;
  region: string;
  space: number;
  status: StorageNodeStatus;
  updatedAt?: string;
  use: number;
  usageBytes: number;
  replacedByStorageNodeID: number;
  replacementProgressPercentage?: number;
}

export interface IStorageType {
  id: StorageProvider;
  name: string;
  displayName: string;
  status: number;
  createdAt: string;
  updatedAt: string;
}

export type StorageProps =
  | IAmazonStorageProps
  | IGoogleStorageProps
  | IAzureStorageProps;

export type AllStorageProps = IAmazonStorageProps &
  IGoogleStorageProps &
  IAzureStorageProps &
  MinIOStorageProps;

export interface IAmazonStorageProps {
  key: string;
  roleArn: string;
  bucket: string;
  region: string;
}

export interface S3CompatibleStorageProps extends IAmazonStorageProps {
  vendor: S3CompatibleVendor;
}

export interface MinIOStorageProps extends S3CompatibleStorageProps {
  endpointURL: string;
}

export interface IGoogleStorageProps {
  client_email: string;
  project_id: string;
  bucket: string;
  region: string;
}

export interface IAzureStorageProps {
  region: string;
  container: string;
  account_name: string;
}

export interface IAddStorageNodeRequest
  extends Omit<IUpdateStorageNodeRequest, "id"> {
  isReplace?: boolean;
  oldStorageNodeId?: number;
}

export interface IUpdateStorageNodeRequest
  extends Omit<IStorageNodeTestConnectionRequest, "secret"> {
  id: number;
  name: string;
  secret?: string;
}

export interface IStorageNodeTestConnectionRequest
  extends Omit<AllStorageProps, "roleArn"> {
  type: StorageProvider;
  secret: string;
  arn: string;
}

export interface IStorageNodeTestConnectionResponse {
  isConnectionSuccessfull: boolean;
  msg: string;
}

export interface IStorageNodesRequestParams extends IRequestParams<StorageNodeSortField> {
}
