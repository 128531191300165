import { IPage } from "../entities/page";
import { DataService } from "./data-service";
import { ISharingInstance, ISharingInstanceDeleteRequest, ISharingInstanceFilterParams, ISharingInstancesRequestParams, ISharingInstanceShort, ISharingInstanceUpdateRequest } from '../entities/sharing-instance';
class SharingInstanceService extends DataService {

  constructor() {
    super("sharing/instance");
  }


  getPage(requestParams: ISharingInstancesRequestParams): Promise<IPage<ISharingInstanceShort>> {
    return this.api.get<IPage<ISharingInstanceShort>>(null, requestParams);
  }

  get(id: number, returnUsedSpaceBytes?: boolean): Promise<ISharingInstance> {
    return this.api.get<ISharingInstance>(id, { returnUsedSpaceBytes });
  }

  update(id: number, updateRequest: ISharingInstanceUpdateRequest): Promise<ISharingInstance> {
    return this.api.putConditionally<ISharingInstance>(id, updateRequest);
  }

  delete(deleteRequest: ISharingInstanceDeleteRequest): Promise<void> {
    return this.api.post("delete", deleteRequest);
  }

  getExportUrl(filterParams?: ISharingInstanceFilterParams): string {
    return this.api.getFullUrl("csv", filterParams);
  }
}

export const sharingInstanceService = new SharingInstanceService();
