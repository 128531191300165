import React, { Component } from "react";
import Loader from "../shared-ui/loader/index";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { LOGIN_ROUTE } from "../../constants/routes";
import { IAppState } from "../../redux/reducers";
import { commonActions } from "../../redux/actions/common";
import { loginCognitoUser } from "../../redux/actions/account";
import localStorageUtils from "../../utils/local-storage-utils";

interface ICognitoPageProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps>,
    RouteComponentProps {}

class CognitoPage extends Component<ICognitoPageProps> {
  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search),
      code = params.get("code"),
      error = params.get("error");

    if (code) {
      if (process.env.REACT_APP_ENV === "local") {
        this.props.loginCognitoUser(code, document.location.href.split("?")[0]);
      } else {
        this.props.loginCognitoUser(code);
      }
    } else {
      if (error) {
        this.props.showSnackBar(true, error);
      }
      this.props.history.replace(LOGIN_ROUTE);
    }
  }

  componentDidUpdate() {
    if (this.props.user) {
      const redirectPath = localStorageUtils.getPostLoginRedirect();
      this.props.history.replace(redirectPath || "/");
    } else if (this.props.loginFailed) {
      this.props.history.replace(LOGIN_ROUTE);
    }
  }

  render() {
    return <Loader />;
  }
}

const mapStateToProps = (state: IAppState) => ({
  user: state.users.currentUser,
  loginFailed: state.account.loginFailed,
});
const mapDispatchToProps = (dispatch) => {
  return {
    loginCognitoUser: (code: string, redirectUri?: string) => {
      dispatch(loginCognitoUser(code, redirectUri));
    },
    showSnackBar: (flag, msg) => {
      dispatch(commonActions.showSnackBar(flag, msg));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CognitoPage));
