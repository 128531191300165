interface IEnviroment {
  API_URL: string,
  COGNITO_LOGIN: string,
  COGNITO_LOGOUT: string;

  SANDBOX?: boolean;
  MIN_SECURITY_LEVEL?: number;
  MIN_STORAGE_COUNT?: number;
  MAX_STORAGE_COUNT?: number;
  CITRIX_AUTH_URL?: string;
  CITRIX_API_KEYS_URL?: string;
  USER_GUIDE_URL?: string;
}

var env: IEnviroment;

if (process.env.REACT_APP_ENV === "localapi") {
  env = {
   COGNITO_LOGIN: "https://myota-dev-build-users.auth.us-east-1.amazoncognito.com/login?response_type=code&scope=email+openid+aws.cognito.signin.user.admin&client_id=pmifhcnjtdd0ki7ren0fnhmat&redirect_uri=http://localhost:5899/cognito",
   COGNITO_LOGOUT:"https://myota-dev-build-users.auth.us-east-1.amazoncognito.com/logout?client_id=pmifhcnjtdd0ki7ren0fnhmat&logout_uri=http://localhost:5899/logout",
   API_URL: "http://localhost:3030/api/v1", 
 };
}
else {
  // loaded at runtime from env.js
  env = (window as any)._env_;
}

export const CONFIG = {
  // these will always be environment specific
  apiURL: env.API_URL,
  cognitoLogin: env.COGNITO_LOGIN,
  cognitoLogout: env.COGNITO_LOGOUT,
  sandbox: env.SANDBOX || false,

  // these will rarely be different thus have common defaults
  minSecurityLevel: env.MIN_SECURITY_LEVEL || 2,
  minStoragesCount: env.MIN_STORAGE_COUNT || 3,
  maxStoragesCount: env.MAX_STORAGE_COUNT || 6,
  citrixAuthUrl: env.CITRIX_AUTH_URL || "https://secure.sharefile.com/oauth/authorize",
  citrixApiKeysUrl: env.CITRIX_API_KEYS_URL || "https://api.sharefile.com/login?displayMessage=1&page=oauthclient",
  userGuideUrl: env.USER_GUIDE_URL || "https://myota.io/wp-content/uploads/2021/01/myota_console-user-guide.pdf",

  isPointOfTimeRecoveryEnabled: false,
  isDataMigrationEnabled: false
};
