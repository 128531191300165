import { ISharingProfileShort } from './sharing-profile';
import { IRequestParams } from "./page";
import { IPolicyShort, IPolicy } from "./policy";
import { IDevice } from "./device";
import { ISharingInstanceShort } from './sharing-instance';
import { IDataMigrationBase } from './data-migration';
import { IIdName } from './id-name';

export enum UserStatus {
  Active = "active",
  Blocked = "blocked",
  Pending = "pending",
}

export enum BlockUserStatus {
  Block = "block",
  Active = "active",
}

export enum UserSortField {
  UserStatus = "userstatus",
  Email = "email",
  UsedSpacePercentage = "usedSpacePercentage",
  FileStatus = "filestatus",
  Profile = "profile",
  Devices = "devices",
  Group = "group",
}

export enum LogoutType {
  Logout = "logout",
  Expire = "expire",
}

export enum UserDataStatus {
  Corrupt = 1,
  Recoverable = 2,
  Safe = 3,
  Recovered = 4,
}

export const userDataStatusToTextMap = {
  [UserDataStatus.Safe]: "Safe",
  [UserDataStatus.Recoverable]: "Recoverable",
  [UserDataStatus.Corrupt]: "Corrupt",
  [UserDataStatus.Recovered]: "Recovered",
};

export interface IUserLoginInfo {
  uid: string;
}

export interface ICurrentUser {
  uid: string;
  name: string;
  email: string;
}

export interface IUserShort extends ICurrentUser {
  id: number;
  status: UserStatus;
}

export interface IUserFileStats {
  usedSpaceBytes: number;
  usedSpacePercentage: number;
  fileStatus: UserDataStatus;
  totalFileCount: number;
}

export interface IUserCommon extends IUserShort, IUserFileStats {
  admin: number;
  type: number;
  Devices: IDevice[];
  dataMigrations?: IDataMigrationBase[];
  Groups: IIdName[];
  repoURI: string;
}

export interface IUser extends IUserCommon {
  Policy: IPolicyShort;
}

export interface IUserDetails extends IUserCommon {
  Policy: IPolicy;
  SharingPolicies: ISharingProfileShort[];
  SharingInstances: ISharingInstanceShort[];
}

export interface IUserFilterParams {
  statusFilter?: UserStatus;
  policyFilter?: number;
  sharingProfileFilter?: number;
  q?: string;
  excludeUserFilter?: number[];
  userGroupFilter?: number;
}

export interface IUsersRequestParams
  extends IRequestParams<UserSortField>,
    IUserFilterParams {
  fields?: (keyof IUser)[]
}
