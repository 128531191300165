import { storagePoolService } from '../../api/services/storage-pool-service';
import { IStoragePoolsRequestParams, IStoragePoolShort, StoragePoolSortField } from './../../api/entities/storage-pool';
import { EntityActions, EntityThunkActions } from './entity';
import { DialogActions } from './dialog';
import { commonActions, handleApi } from './common';
import { IStorageNode } from '../../api/entities/storage-node';
import { IDeleteBlockedConfirmationData } from '../../components/storage-pools/dialogs/delete-blocked-confirmation/delete-blocked-confirmation';

export const STORAGE_POOL_ENTITY_NAME = "storagePool";
export const DELETE_CONFIRMATION_DIALOG_NAME = "deleteConfirmation";
export const DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME = "deleteBlockedConfirmation";
export const REPLACE_STORAGE_CONFIRMATION = "replaceStorageConfirmation";

class StoragePoolActions {
  readonly entity = new EntityActions<IStoragePoolShort, IStoragePoolsRequestParams>(STORAGE_POOL_ENTITY_NAME);
  readonly deleteConfirmation = new DialogActions<IStoragePoolShort>(STORAGE_POOL_ENTITY_NAME, DELETE_CONFIRMATION_DIALOG_NAME);
  readonly deleteBlockedConfirmation = new DialogActions<IDeleteBlockedConfirmationData>(STORAGE_POOL_ENTITY_NAME, DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME);
  readonly replaceStorageConfirmation = new DialogActions<IStorageNode>(STORAGE_POOL_ENTITY_NAME, REPLACE_STORAGE_CONFIRMATION);
}

export const storagePoolActions = new StoragePoolActions();

class StoragePoolThunkActions {
  readonly entity = new EntityThunkActions<IStoragePoolShort, StoragePoolSortField, IStoragePoolsRequestParams>(
    STORAGE_POOL_ENTITY_NAME, storagePoolService)

  delete(storagePoolId: number) {
    return (dispatch) => {
      return handleApi(storagePoolService
        .delete(storagePoolId)
        .then(() => {
          dispatch(
            commonActions.showSnackBar(
              true,
              `Storage Pool successfully deleted.`
            )
          );
          dispatch(this.entity.fetchPage());
        }), true);
    };
  }
}
export const storagePoolThunkActions = new StoragePoolThunkActions();

