import { AccountAction, AccountActionType } from "../actions/account";

export interface IAccountState {
  loginFailed: boolean;
}

export const ACCOUNT_DEFAULT_STATE: IAccountState = {
  loginFailed: false,
};

export default function users(
  state = ACCOUNT_DEFAULT_STATE,
  action: AccountAction
): IAccountState {
  switch (action.type) {
    case AccountActionType.LoginError:
      return {
        ...state,
        loginFailed: true,
      };
    case AccountActionType.RemoveLoginError:
      return {
        ...state,
        loginFailed: false,
      };
    default:
      return state;
  }
}
