import { ISharingProfileShort } from "../api/entities/sharing-profile";
import { dataMigrationUtils } from "./data-migration-utils";

class SharingProfileUtils {
    canSelectSharingProfile(sharingProfile: ISharingProfileShort): boolean {
        return !dataMigrationUtils.hasActiveMigrations(sharingProfile); 
    }
    canEditSharingProfile(sharingProfile: ISharingProfileShort): boolean {
        return !dataMigrationUtils.hasActiveMigrations(sharingProfile); 
    }
    canManageSharingProfile(sharingProfile: ISharingProfileShort): boolean {
        return !dataMigrationUtils.hasActiveMigrations(sharingProfile); 
    }
}

export const sharingProfileUtils = new SharingProfileUtils();