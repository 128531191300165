import React, { ReactNode, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styles from "./left-panel.module.scss";
import clsx from "clsx";
import { IStyleableComponentProps } from "../common/component-props";
import { IAppState } from "../../../redux/reducers";
import { connect } from "react-redux";
import { SidePanelPosition, SidePanel } from "../side-panel/side-panel";
import logoPng from "../../../assets/myota_logo_white_type.png";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { commonActions } from "../../../redux/actions/common";

const mapStateToProps = (state: IAppState) => ({
  isOpen: state.common.isLeftPanelOpen,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, void, Action>
) => {
  return {
    setLeftPanelOpen: (isOpen: boolean) => dispatch(commonActions.setLeftPanelOpen(isOpen)),
  };
};

interface ILeftPanelProps
  extends IStyleableComponentProps,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
  children: ReactNode;
}

const LeftPanel = connect(mapStateToProps, mapDispatchToProps)((props: ILeftPanelProps) => {
  const history = useHistory();
  
  useEffect(() => history.listen(() => {
    props.setLeftPanelOpen(false);
    // eslint-disable-next-line
  }), [])

  return (
    <SidePanel
      side={SidePanelPosition.Left}
      open={props.isOpen}
      className={clsx(styles.root, props.className)}
      rootClass={clsx(styles["side-panel"], styles["left-panel"], props.isOpen && styles["side-panel--open"])}
      style={props.style}
    >
      <div className={styles.content}>
        <Link to="/" className={`${styles["logo-link"]} hidden-on-small-screen`}>
          <img src={logoPng} alt="Myota-logo" />
        </Link>
        {props.children}
      </div>
    </SidePanel>
  );
});

export default LeftPanel;
