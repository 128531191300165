import { IPage } from '../entities/page';
import { IStoragePool, IStoragePoolCreateRequest, IStoragePoolShort, IStoragePoolsRequestParams, IStoragePoolUpdateRequest } from './../entities/storage-pool';
import { ApiVersion } from './api-service';
import { DataService } from "./data-service";

class StoragePoolService extends DataService {
    constructor() {
        super("storagepool");
    }

    get(storagePoolId: number): Promise<IStoragePool> {
        return this.api.get<IStoragePool>(storagePoolId);
    }

    addStoragePool(request: IStoragePoolCreateRequest) {
        return this.api.post<IStoragePool>(null, request);
    }

    getPage(requestParams: IStoragePoolsRequestParams): Promise<IPage<IStoragePoolShort>> {
        return this.api.get<IPage<IStoragePoolShort>>(null, requestParams, ApiVersion.V1_1);
    }
        
    update(updateRequest: IStoragePoolUpdateRequest): Promise<IStoragePool> {
        return this.api.put<IStoragePool>(updateRequest.id, updateRequest);
    }
    
    delete(storagePoolId: number): Promise<void> {
        return this.api.delete(storagePoolId);
    }
}

export const storagePoolService = new StoragePoolService();