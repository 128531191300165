import { makeStyles, createStyles } from "@mui/styles";
import { theme } from "../../theme/theme";


export const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      body: {
        //  backgroundColor: theme.palette.common.white,
      },
    },
    container: {
      border: "solid black",
      width: "100%",
      height: "100vh",
      backgroundImage: "linear-gradient(to bottom, #000410, #171f38)",
    },
    loginwrap: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "100%",
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    logoimg: {
      maxWidth: "360px",
      marginBottom: "22px",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    formwrap: {
      marginBottom: "26px",
    },
    forminput: {
      borderRadius: "25px",
      lineHeight: 1.3,
      backgroundColor: "#fff",
      border: "solid 2px #6c969d",
      fontSize: "14px",
      color: "#292929",
      height: "auto",
      padding: "11px 29px",
      margin: 0,
    },
    focused: {
      outline: "none",
      boxShadow: "none",
      border: "none",
      borderColor: "none",
    },
    underline: {
      "&:hover": {
        "&::before": {
          borderBottom: "0px !important",
        },
      },
      "&::before": {
        borderBottom: "0px",
      },
      "&::after": {
        borderBottom: "0px",
      },
    },
    title: {
      textAlign: "center",
      color: "white",
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: 1.18,
      marginBottom: "29px",
      fontFamily: "Raleway",
    },
    inputLabel: {
      marginBottom: "8px",
      paddingLeft: "17px",
      fontSize: "14px",
      fontWeight: 600,
      textTransform: "capitalize",
      color: "#fff",
    },
    loginButton: {
      marginTop: "7px",
      marginLeft: "8px",
    },
    loginFailedTitle: {
      fontFamily: "Raleway",
      fontSize: "34px",
      color: "#fff",
      marginBottom: "7px",
      textAlign: "center",
    },
    loginFailedInfo: {
      fontSize: "20px",
      color: "#e3e3e3",
      textAlign: "center",
    },
  })
);
