import { ApiException } from './../../api/services/api-service';
import { IStorageNodeIssue } from './../../api/entities/storage-node-issue';
import {
  IStorageNode,
  IStorageType,
  IAddStorageNodeRequest,
  IStorageNodeTestConnectionRequest,
  IUpdateStorageNodeRequest,
} from "./../../api/entities/storage-node";
import { commonActions, catchException, handleApi } from "./common";
import { storageNodeService } from "../../api/services/storage-node-service";
import { storageTypeService } from "../../api/services/storage-type-service";
import { storageNodeIssuesService } from '../../api/services/storage-node-issues-service';
import { IStorageNodePerformanceSearchParams } from '../../api/entities/storage-node-performance';
import { DialogActions } from './dialog';
import { storageUtils } from '../../utils/storage-utils';
import { dashboardService } from '../../api/services/dashboard-service';

export enum StorageActionType {
  PROVIDER_TYPE = "PROVIDER_TYPE",
  CHECK_UNIQUE_NAME = "CHECK_UNIQUE_NAME",
  STORAGE_LISTING = "STORAGE_LISTING",
  SET_TEST_CONNECTION_FLAG = "SET_TEST_CONNECTION_FLAG",
  SET_STORAGE_NODE_DETAIL = "SET_STORAGE_NODE_DETAIL",
  STORAGE_ISSUES_FETCH_SUCCESS = "STORAGE_ISSUES_FETCH_SUCCESS",
}

export const REPLACE_STORAGE_CONFIRMATION = "replaceStorageConfirmation";
export const STORAGE_ENTITY_NAME = "storage";

class StorageActions {

  readonly replaceStorageConfirmation = new DialogActions<IStorageNode>(STORAGE_ENTITY_NAME, REPLACE_STORAGE_CONFIRMATION);

  fetchStorageIssuesSuccess(data: IStorageNodeIssue[]) {
    return {
      type: StorageActionType.STORAGE_ISSUES_FETCH_SUCCESS as typeof StorageActionType.STORAGE_ISSUES_FETCH_SUCCESS,
      payload: data,
    };
  }

  storageListing(data: IStorageNode[]) {
    return {
      type: StorageActionType.STORAGE_LISTING as typeof StorageActionType.STORAGE_LISTING,
      payload: data,
    };
  }

  //--------------------------Get Provider Type Api Call--------------------//
  ProviderType(data: IStorageType[]) {
    return {
      type: StorageActionType.PROVIDER_TYPE as typeof StorageActionType.PROVIDER_TYPE,
      payload: data,
    };
  }

  uniqueName(data: boolean) {
    return {
      type: StorageActionType.CHECK_UNIQUE_NAME as typeof StorageActionType.CHECK_UNIQUE_NAME,
      data,
    };
  }

  setTestConnectionFlag(flag: boolean) {
    return {
      type: StorageActionType.SET_TEST_CONNECTION_FLAG as typeof StorageActionType.SET_TEST_CONNECTION_FLAG,
      flag: flag,
    };
  }

  setStorageNodeDetail(data: IStorageNode) {
    return {
      type: StorageActionType.SET_STORAGE_NODE_DETAIL as typeof StorageActionType.SET_STORAGE_NODE_DETAIL,
      data: data,
    };
  }

  fetchStorageIssues() {
    return (dispatch) => {
      handleApi(storageNodeIssuesService
        .getStorageNodeIssues()
        .then((data) => {
          if (data && data.length) {
            dispatch(this.fetchStorageIssuesSuccess(data));
          }
        }), true);
    };
  }

  fetchStorageNodesPerformance(searchParams: IStorageNodePerformanceSearchParams) {
    return (dispatch) => {
      return handleApi(dashboardService
        .storageSpeed(searchParams));
    };
  }

  getProviderType() {
    return (dispatch) => {
      handleApi(storageTypeService
        .getStorageTypes()
        .then((data) => {
          if (data) {
            dispatch(this.ProviderType(data));
          }
        }), true);
    };
  }

  //----------------api call to Add New Storage-----------//
  addStorage(payload: IAddStorageNodeRequest) {
    return (dispatch) => {
      return handleApi(storageNodeService.addStorageNode(storageUtils.cleanNotApplicableFields(payload)).then((data) => {
        if (data) {
          dispatch(commonActions.showSnackBar(true, "Storage successfully added."));
          dispatch(this.getStorageList());
        }
        return data;
      }), true);
    }
  }

  //-------------API call for fetching all storages ------------------//
  getStorageList() {
    return (dispatch) => {
      handleApi(storageNodeService
        .getStorageNodes()
        .then((page) => {
          if (page) {
            dispatch(this.storageListing(page.data));
          }
        }), true);
    };
  }

  //-------------API call for checking unique names------------------//
  checkUniqueName(name: string, storageType: number) {
    return (dispatch) => {
      if (name.length > 3) {
        handleApi(storageNodeService
          .nameValidator(name, storageType)
          .then((data) => {
            dispatch(this.uniqueName(data));
          }), true);
      } else {
        dispatch(this.uniqueName(false));
      }
    };
  }

  //----------------api call to Test Connection-----------//
  testConnection(payload: IStorageNodeTestConnectionRequest) {
    return (dispatch) => {
      dispatch(commonActions.startLoading());
      storageNodeService.testConnection(storageUtils.cleanNotApplicableFields(payload))
        .then((result) => {
          dispatch(commonActions.stopLoading());
          dispatch(this.setTestConnectionFlag(result.isConnectionSuccessfull));
          if (!result.isConnectionSuccessfull && result.msg) {
            dispatch(commonActions.showSnackBar(true, result.msg));
          }
        }).catch((exception: ApiException) => {
          dispatch(this.setTestConnectionFlag(false));
          throw exception;
        }).catch(catchException());
    };
  }

  //-------------API call for checking unique names------------------//
  getNodeDetail(id: number) {
    return (dispatch) => {
      handleApi(storageNodeService
        .getStorageNode(id)
        .then((data) => {
          if (data) {
            dispatch(storageActions.setStorageNodeDetail(data));
          }
        }), true);
    };
  }

  //----------------api call to Update storage node-----------//
  updateStorage(payload: IUpdateStorageNodeRequest) {
    return (dispatch) => {
      handleApi(storageNodeService.updateStorageNode(storageUtils.cleanNotApplicableFields(payload))
        .then((data) => {
          if (data) {
            dispatch(commonActions.showSnackBar(true, "Storage successfully updated."));
          }
          dispatch(this.getStorageList());
          dispatch(this.getNodeDetail(payload.id));
        }), true);
    };
  }
}

export const storageActions = new StorageActions();

export type StorageAction =
  | ReturnType<typeof storageActions.fetchStorageIssuesSuccess>
  | ReturnType<typeof storageActions.storageListing>
  | ReturnType<typeof storageActions.ProviderType>
  | ReturnType<typeof storageActions.uniqueName>
  | ReturnType<typeof storageActions.setTestConnectionFlag>
  | ReturnType<typeof storageActions.setStorageNodeDetail>;
