import { commonActions, handleApi } from "./common";
import { initialSetupActions } from "./intialSetup";
import { policyService } from "../../api/services/policy-service";
import {
  IPolicy,
  IUpdateUsersRequest,
  IPolicyUpdate,
  IPolicyAdd,
  IPoliciesRequestParams,
  PolicySortField,
} from "../../api/entities/policy";
import { DialogActions } from "./dialog";
import { EntityActions, EntityThunkActions } from "./entity";
import { InitialSetupStep } from "../../components/initialSetup";
import { policyUtils } from "../../utils/policy-utils";

export enum StorageProfileActionType {
  SetInitialSearchParams = "STORAGE_PROFILE_SET_INITIAL_SEARCH_PARAMS",
  SetStorageProfileDetail = "STORAGE_PROFILE_SET_DETAIL",
  SetStorageProfileUpdateData = "STORAGE_PROFILE_SET_UPDATE_DATA",
}

export const MAKE_DEFAULT_CONFIRMATION = "makeDefaultConfirmation";
export const STORAGE_PROFILE_ENTITY_NAME = "storageProfile";

class StorageProfileActions {

  readonly makeDefaultConfirmation = new DialogActions<IPolicy>(STORAGE_PROFILE_ENTITY_NAME, MAKE_DEFAULT_CONFIRMATION);
  readonly entity = new EntityActions<IPolicy, IPoliciesRequestParams>(STORAGE_PROFILE_ENTITY_NAME);

  setStorageProfileDetail(data: IPolicy) {
    return {
      type: StorageProfileActionType.SetStorageProfileDetail as typeof StorageProfileActionType.SetStorageProfileDetail,
      data,
    };
  }
  setStorageProfileUpdateData(data: IPolicyUpdate) {
    return {
      type: StorageProfileActionType.SetStorageProfileUpdateData as typeof StorageProfileActionType.SetStorageProfileUpdateData,
      data,
    };
  }
  updateStorageProfile(payload: IPolicyUpdate) {
    return dispatch => {
      return handleApi(policyService
        .update(payload)
        .then(_ => {
          dispatch(
            commonActions.showSnackBar(
              true,
              "Storage profile successfully updated."
            )
          );
          dispatch(storageProfileThunkActions.entity.fetchPage());
        }), true);
    };
  }
}

export const storageProfileActions = new StorageProfileActions();
export type StorageProfileAction =
  | ReturnType<typeof storageProfileActions.setStorageProfileDetail>
  | ReturnType<typeof storageProfileActions.setStorageProfileUpdateData>;


class StorageProfileThunkActions {
  readonly entity = new EntityThunkActions<IPolicy, PolicySortField, IPoliciesRequestParams>(
    STORAGE_PROFILE_ENTITY_NAME, policyService, (policy) => policyUtils.canSelectPolicy(policy))

  //-----------------api call for Add New Storage Profile-------------------//
  addNewStorageProfileProfile(payload: IPolicyAdd) {
    return dispatch => {
      return handleApi(policyService
        .create(payload)
        .then(_ => {
          dispatch(
            commonActions.showSnackBar(
              true,
              "Storage profile successfully added."
            )
          );
          dispatch(this.entity.fetchPage());
          //TODO need to refactor this
          if (window.location.href.includes("initial-setup")) {
            dispatch(initialSetupActions.setStep(InitialSetupStep.Finish));
          }
        }), true);
    };
  }

  //-------------API call for get security profile detail on base of id------------------//
  getProfileDetail(id: number) {
    return dispatch => {
      return handleApi(policyService
        .get(id)
        .then(data => {
          if (data) {
            dispatch(storageProfileActions.setStorageProfileDetail(data));
          }
        }), true);
    };
  }

  //-----------------api call for Delete Storage Profile-------------------//
  deleteStorageProfiles(policyIds: number[], force?: boolean) {
    return dispatch => {
      dispatch(commonActions.closeAlert());
      return handleApi(policyService
        .delete({ id: policyIds, force })
        .then(_ => {
          dispatch(
            commonActions.showSnackBar(
              true,
              `Storage profile successfully deleted.`
            )
          );
          dispatch(commonActions.setConfirmationFlag(false));
          dispatch(this.entity.fetchPage());
        }), true);
    };
  }

  updateStorageProfileUsers(payload: IUpdateUsersRequest, id: number) {
    return dispatch => {
      return handleApi(policyService
        .updateUsers(id, payload)
        .then(_ => {
          dispatch(
            commonActions.showSnackBar(
              true,
              "Storage profile users successfully updated."
            )
          );
          dispatch(this.entity.fetchPage());
        }), true);
    };
  }

}

export const storageProfileThunkActions = new StorageProfileThunkActions();
