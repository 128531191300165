import React, { useCallback, useState, useEffect } from "react";
import styles from "./search-input.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import IComponentProps from "../../common/component-props";
import clsx from "clsx";
import _ from "underscore";

interface ISearchInputProps extends IComponentProps {
  value: string;
  placeholder?: string;
  onChangeDebounce?: number;

  onChange: (value: string) => void;
}

export default function SearchInput(props: ISearchInputProps) {
  const [value, setValue] = useState(props.value || "");

  // eslint-disable-next-line
  const onChange = useCallback(
     _.debounce(props.onChange, props.onChangeDebounce),
    [props.onChangeDebounce]
  );

  useEffect(() => {
    setValue(props.value ?? "");
  }, [props.value]);

  return (
    <div
      className={clsx(styles.root, props.className)}
      data-testid={props.testId}
    >
      <input
        value={value}
        onChange={e => { 
          setValue(e.target.value); 
          onChange(e.target.value);
        }}
        placeholder={props.placeholder || "Search…"}
        className={styles.input}
        aria-label="search"
        disabled={props.disabled}
        style={props.style}
      />
      <div className={`${styles["icon-root"]} hidden-on-small-screen`}>
        <SearchIcon className={styles.icon} />
      </div>
    </div>
  );
}
