import { LOGOUT_SUCCESS_ROUTE, SCAN_HISTORY_ROUTE, SharingProfileRouteAction, SHARING_PROFILES_ROUTE, USERS_ROUTE, UsersRouteAction, DevicesRouteAction, SHARING_INSTANCES_ROUTE, SharingInstanceRouteAction, StorageProfileRouteAction, STORAGE_POOLS_ROUTE, StoragePoolRouteAction, SETTINGS_ROUTE, SettingsRouteSection, SettingsRouteAction, USER_GROUPS_ROUTE } from './../constants/routes';
import { DEVICES_ROUTE } from "../constants/routes";
import { STORAGE_PROFILES_ROUTE } from "../constants/routes";
import { CONFIG } from '../config/config';
import { ISharingInstanceFilterParams } from '../api/entities/sharing-instance';
import { queryUtils } from './query-utils';
export class RouteUtils {

    
    getRouteToActiveDirectorySettings(): string {
        return `${SETTINGS_ROUTE}/${SettingsRouteSection.ActiveDirectorySettings}`;
    }

    getRouteToCliCredentials(): string {
        return `${SETTINGS_ROUTE}/${SettingsRouteSection.CliCredentials}`;
    }

    getRouteToCertificateSettings(certificateId: number): string {
        return `${SETTINGS_ROUTE}/${SettingsRouteSection.AutoApprovalCertificate}/${certificateId}`;
    }

    getRouteToCertificateGuide(certificateId?: number): string {
        if (certificateId || certificateId === 0) {
            return `${SETTINGS_ROUTE}/${SettingsRouteSection.AutoApprovalCertificate}/${certificateId}/${SettingsRouteAction.Guide}`;
        }
        else {
            return `${SETTINGS_ROUTE}/${SettingsRouteSection.AutoApprovalCertificateGuide}`;
        }
    }

    getRouteToStorageNode(storageNodeId: number): string {
        return `${STORAGE_POOLS_ROUTE}/${StoragePoolRouteAction.ManageNode}/${storageNodeId}`;
    }

    getRouteToDevice(deviceId: number) {
        return `${DEVICES_ROUTE}/${deviceId}`;
    }

    getRouteToDeviceLoginHistory(deviceId: number) {
        return `${DEVICES_ROUTE}/${deviceId}/${DevicesRouteAction.LoginHistory}`;
    }

    getRouteToStorageProfile(policyId: number) {
        return `${STORAGE_PROFILES_ROUTE}/${policyId}`;
    }

    getRouteToStorageProfileManageUsers(policyId: number) {
        return `${STORAGE_PROFILES_ROUTE}/${policyId}/${StorageProfileRouteAction.ManageUsers}`;
    }

    getRouteToStorageProfileManageExceededUsers(policyId: number) {
        return `${STORAGE_PROFILES_ROUTE}/${policyId}/${StorageProfileRouteAction.ManageExceededUsers}`;
    }

    getRouteToLogoutSuccessPage() {
        return `${LOGOUT_SUCCESS_ROUTE}?login_uri=${encodeURIComponent(CONFIG.cognitoLogin)}`;
    }

    getRouteToScanHistoryDetails(scanId: string) {
        return `${SCAN_HISTORY_ROUTE}/${scanId}`;
    }

    getRouteToScanHistoryUserDetails(scanId: string, userId: number) {
        return `${SCAN_HISTORY_ROUTE}/${scanId}/${userId}`;
    }

    getRouteToSharingProfileDetails(sharingProfileId: number) {
        return `${SHARING_PROFILES_ROUTE}/${sharingProfileId}`;
    }

    getRouteToSharingInstanceDetails(sharingInstanceId: number) {
        return `${SHARING_INSTANCES_ROUTE}/${sharingInstanceId}`;
    }

    getRouteToSharingInstanceManageUsers(sharingInstanceId: number) {
        return `${SHARING_INSTANCES_ROUTE}/${sharingInstanceId}/${SharingInstanceRouteAction.ManageUsers}`;
    }

    getRouteToSharingProfileManageUsers(sharingProfileId: number) {
        return `${SHARING_PROFILES_ROUTE}/${sharingProfileId}/${SharingProfileRouteAction.ManageUsers}`;
    }

    getRouteToStoragePoolDetails(storagePoolId: number) {
        return `${STORAGE_POOLS_ROUTE}/${storagePoolId}`;
    }
    
    getRouteToUserDetails(uid: string) {
        return `${USERS_ROUTE}/${uid}`;
    }

    getRouteToUserMigrateFiles(uid: string) {
        return `${USERS_ROUTE}/${uid}/${UsersRouteAction.MigrateFiles}`;
    }

    getRouteToUserGroupDetails(id: number) {
        return `${USER_GROUPS_ROUTE}/${id}`;
    }
    
    getRouteToUserRestoreFiles(userUid: string) {
        return `${USERS_ROUTE}/${userUid}/${UsersRouteAction.RestoreFiles}`;
    }

    getRouteToUsersRestoreFiles() {
        return `${USERS_ROUTE}/${UsersRouteAction.RestoreFiles}`;
    }

    getRouteToStoragePoolsRestoreFiles() {
        return `${STORAGE_POOLS_ROUTE}/${StoragePoolRouteAction.RestoreFiles}`;
    }

    getRouteToStoragePoolRestoreFiles(storagePoolId: number) {
        return `${STORAGE_POOLS_ROUTE}/${storagePoolId}/${StoragePoolRouteAction.RestoreFiles}`;
    }

    getRouteToSharingInstances(filter: ISharingInstanceFilterParams) {
        return `${SHARING_INSTANCES_ROUTE}?${queryUtils.toQuery(filter)}`;
    }

    getRouteToSharingInstancesRestoreFiles() {
        return `${SHARING_INSTANCES_ROUTE}/${SharingInstanceRouteAction.RestoreFiles}`;
    }

    getRouteToSharingInstanceRestoreFiles(sharingInstanceId: number) {
        return `${SHARING_INSTANCES_ROUTE}/${sharingInstanceId}/${SharingInstanceRouteAction.RestoreFiles}`;
    }
}

export const routeUtils = new RouteUtils();