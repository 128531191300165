import { IPolicy } from "../api/entities/policy";
import { dataMigrationUtils } from "./data-migration-utils";

export class PolicyUtils {
    canSelectPolicy(policy: IPolicy): boolean {
        return !dataMigrationUtils.hasActiveMigrations(policy); 
    }
    canEditPolicy(policy: IPolicy): boolean {
        return !dataMigrationUtils.hasActiveMigrations(policy); 
    }
    canManagePolicy(policy: IPolicy): boolean {
        return !dataMigrationUtils.hasActiveMigrations(policy); 
    }
}

export const policyUtils = new PolicyUtils();