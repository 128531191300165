import React, { useEffect } from "react";
import SidePanelSection from "../../../shared-ui/side-panel-section/side-panel-section";
import styles from "./storage-node-section.module.scss";
import { FormField } from "../../../shared-ui/form/form-field/form-field";
import { Select } from "../../../shared-ui/select";
import { StorageProviderField, storageProviderToRegionFieldLabelMap, storageProviderToSecretFieldLabelMap, storageUtils } from "../../../../utils/storage-utils";
import { enumUtils } from "../../../../utils/enum-utils";
import { IStorageNodeTestConnectionRequest, S3CompatibleVendor, StorageProvider } from "../../../../api/entities/storage-node";
import { IFormField } from "../../../../utils/form-utils";
import { FormSubSection } from "../../../shared-ui/form/form-sub-section/form-sub-section";
import Button from "../../../shared-ui/button";
import TextInput from "../../../shared-ui/input/text-input/text-input";
import { RegionSelect } from "../../region-select/region-select";
import { FormFieldValue } from "../../../shared-ui/form/form-field-value/form-field-value";
import { TestConnection } from "../../../initialSetup/storage-pool/test-connection/test-connection";
import { FormFieldLabel } from "../../../shared-ui/form/form-field-label/form-field-label";
import { CONFIG } from "../../../../config/config";


export interface IStorageNodeForm {
  type: IFormField<StorageProvider>;
  name: IFormField<string>;
  region: IFormField<string>;
  isCustomRegion: IFormField<boolean>;
  vendor: IFormField<S3CompatibleVendor>;
  endpointURL: IFormField<string>;
  bucket: IFormField<string>;
  account_name: IFormField<string>;
  container: IFormField<string>;
  arn: IFormField<string>;
  project_id: IFormField<string>;
  key: IFormField<string>;
  secret: IFormField<string>;
  client_email: IFormField<string>;
  storeContent: IFormField<boolean>,
  storeMetadata: IFormField<boolean>,
  storeKey: IFormField<boolean>,
}

export interface IStorageNodeFormValue extends IStorageNodeTestConnectionRequest {
  name: string;
  isCustomRegion: boolean;
}

export interface IStorageNodeSectionProps {
  form: IStorageNodeForm;
  testConnectionRequest: IStorageNodeTestConnectionRequest;
  isTestConnectionSuccess: boolean;
  onTestConnection: (testConnectionRequest: IStorageNodeTestConnectionRequest) => void;
}

export function StorageNodeSection(props: IStorageNodeSectionProps) {
  const { form, testConnectionRequest } = props;
  
  useEffect(() => {
    function isFieldVisible(field: StorageProviderField) {
      return storageUtils.isFieldVisible(field, form.type.value, form.vendor.value);
    }

    form.region.setIsVisible(isFieldVisible(StorageProviderField.Region));
    form.vendor.setIsVisible(isFieldVisible(StorageProviderField.Vendor));
    form.endpointURL.setIsVisible(isFieldVisible(StorageProviderField.EndpointURL));
    form.bucket.setIsVisible(isFieldVisible(StorageProviderField.Bucket));
    form.account_name.setIsVisible(isFieldVisible(StorageProviderField.AccountName));
    form.container.setIsVisible(isFieldVisible(StorageProviderField.Container));
    form.arn.setIsVisible(isFieldVisible(StorageProviderField.Arn));
    form.project_id.setIsVisible(isFieldVisible(StorageProviderField.ProjectId));
    form.key.setIsVisible(isFieldVisible(StorageProviderField.AccessKey));;
    form.secret.setIsVisible(isFieldVisible(StorageProviderField.SecretKey));
    form.client_email.setIsVisible(isFieldVisible(StorageProviderField.ClientEmail));

    // eslint-disable-next-line
  }, [form.type.value, form.vendor.value]);

  return (   
    <SidePanelSection 
      testId="storage-node-section" 
      title="Storage Node Configuration"
      contentClass={styles.content}
    >
      <div>
        <FormField testId="provider" className={styles["field-provider"]} field={form.type}>
          <FormFieldLabel noLeftMargin={true}>
            {form.type.isDisabled ? "Storage provider:" : "Select a storage provider:"}
          </FormFieldLabel>
          <FormFieldValue>
            <Select
              field={form.type}
              options={enumUtils.values(StorageProvider).map((provider: StorageProvider) => ({
                value: provider,
                label: storageUtils.getStorageProviderName(provider)
              }))}
            />
          </FormFieldValue>
        </FormField>
      </div>
      {form.type.value && (
        <FormSubSection className={styles["provider-properties"]}>
          <div className={styles.description}>
            Please provide the following configuration details for your {storageUtils.getStorageProviderShortName(form.type.value)} account.<br/> 
            If you are unsure where to find this info, please refer to{" "}
            <Button testId="guide-button" colors="link" openLinkInNewWindow={true} href={CONFIG.userGuideUrl}>our guide</Button>. 
          </div>
          <div className={styles["provider-fields-container"]}>
            <div className={styles["provider-fields"]}>
              
              <FormField 
                testId="storage-name" 
                label="Storage Name"
                field={form.name}
              >
                <TextInput
                  testId="storage-name-input"
                  autoFocus={true}
                  field={form.name}
                />
              </FormField>

              {form.region.isDisabled && (
                  <FormField 
                    testId="region" 
                    label="Region"
                  >
                    <TextInput
                      testId="region-input" 
                      field={form.region}
                    />
                  </FormField>
              )}
              {form.storeContent.isDisabled && form.storeKey.isDisabled && form.storeMetadata.isDisabled && (
                <FormField 
                  testId="storage-type" 
                  label="Storage Type"
                >
                  <TextInput
                    testId="storage-type-input" 
                    disabled={true}
                    value={[
                      form.storeContent.value && "Content", 
                      form.storeKey.value && "Key", 
                      form.storeMetadata.value && "Metadata"].filter(v => v).join(", ")}
                  />
                </FormField>
              )}
              {!form.region.isDisabled && (
                <FormField 
                  label={`Choose a ${(storageProviderToRegionFieldLabelMap[form.type.value] || "region").toLowerCase()}`} 
                  testId="region"
                  field={form.region}
                >              
                  <RegionSelect
                    testId="region-select"
                    isCustomRegion={form.isCustomRegion.value}
                    provider={form.type.value}
                    value={form.region.value}
                    onChange={(region, isCustomRegion) => {
                      form.region.onChange(region);
                      form.isCustomRegion.onChange(isCustomRegion);
                    }}
                  />
                </FormField>
              )}

              <FormField 
                label="Vendor" 
                testId="vendor"
                field={form.vendor}
              >
                <FormFieldValue>
                  <Select
                    testId="vendor-select"
                    field={form.vendor}
                    options={[S3CompatibleVendor.MinIO]}
                  />
                </FormFieldValue>
              </FormField>

              <FormField 
                label="Endpoint URL" 
                testId="endpoint-url" 
                field={form.endpointURL}
              >
                <TextInput
                  testId="endpoint-url-input" 
                  field={form.endpointURL}
                />
              </FormField>

              <FormField 
                label="Bucket Name" 
                testId="bucket-name" 
                field={form.bucket}
              >
                <FormFieldValue>
                  <TextInput
                    testId="bucket-name-input" 
                    field={form.bucket}
                  />
                </FormFieldValue>
              </FormField>

              <FormField 
                label="Account Name" 
                testId="account-name" 
                field={form.account_name}
              >
                <TextInput
                  testId="account-name-input"
                  field={form.account_name}
                />
              </FormField>

              <FormField 
                label="Container" 
                testId="container" 
                field={form.container}
              >
                <TextInput
                  testId="container-input" 
                  field={form.container}
                />
              </FormField>

              <FormField 
                label="Role/Arn" 
                testId="idp-role" 
                field={form.arn}
              >
                <TextInput
                  testId="idp-role-input" 
                  field={form.arn}
                />
              </FormField>

              <FormField 
                label="Project Id" 
                testId="project-id" 
                field={form.project_id}
              >
                <TextInput
                  testId="project-id-input" 
                  field={form.project_id}
                />
              </FormField>

              <FormField 
                label="Access Key" 
                testId="access-key" 
                field={form.key}
              >
                <TextInput
                  testId="access-key-input" 
                  field={form.key}
                />
              </FormField>

              <FormField
                label={storageProviderToSecretFieldLabelMap[form.type.value]}
                testId="secret-key"
                field={form.secret}
              >
                <TextInput
                  testId="secret-key-input"
                  field={form.secret}
                />
              </FormField>

              <FormField 
                label="Client Email" 
                testId="client-email" 
                field={form.client_email}
              >
                <TextInput
                  testId="client-email-input"
                  field={form.client_email}
                />
              </FormField>
            </div>
            <div className={styles["test-button-container"]}>
              <TestConnection 
                testConnectionRequest={testConnectionRequest}
                isTestConnectionSuccess={props.isTestConnectionSuccess}
                testConnection={props.onTestConnection}
              />
            </div>
          </div>
        </FormSubSection>
      )}
    </SidePanelSection>
  );
}