export const DEFAULT_PAGE_SIZE = 10;
export const ALLOTTED_SPACE_PRECISION = 2;
export const OPERATIONS_COUNT_PRECISION = 0;
export const PERCENTAGE_PRECISION = 0;
export const APP_ROOT_ELEMENT_ID = "root";
export const MINUTE_SECONDS = 60;
export const HOUR_MINUTES = 60;
export const DAY_HOURS = 24;
export const HALF_DAY_HOURS = DAY_HOURS/2;
export const HOUR_SECONDS = MINUTE_SECONDS * HOUR_MINUTES;
export const DAY_SECONDS = HOUR_SECONDS * DAY_HOURS;
export const MONTH_DAYS = 31;
export const YEAR_DAYS = 365;
export const MAX_PORT_NUMBER = 65535;

/** Metric System Units */
export const MS_UNIT = Object.freeze({
  K: 1000,
  M: Math.pow(10, 6),
  G: Math.pow(10, 9),
  T: Math.pow(10, 12),
});

export const IS_MOBILE = window.screen.width <= 768;
