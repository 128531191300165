import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { InitialSetupStep } from "..";
import { IStoragePoolCreateRequest } from "../../../api/entities/storage-pool";
import { storageNodeService } from "../../../api/services/storage-node-service";
import { storagePoolService } from "../../../api/services/storage-pool-service";
import { CONFIG } from "../../../config/config";
import { commonActions } from "../../../redux/actions/common";
import { initialSetupActions } from "../../../redux/actions/intialSetup";
import { IAppState } from "../../../redux/reducers";
import { useField } from "../../../utils/form-utils";
import { useApi } from "../../../utils/hooks/use-api";
import { validators } from "../../../utils/validators";
import PageTitle from "../../shared-ui/pageTitle";
import { IStoragePoolNode, validateStoragePoolNodes } from "../../storage-pools/sections/storage-nodes-section/storage-nodes-section";
import { IStoragePoolForm, StoragePoolForm, StoragePoolFormMode } from "../../storage-pools/storage-pool-form/storage-pool-form";


const mapDispatchToProps = (dispatch: ThunkDispatch<IAppState, void, Action>) => {
  return {
    startLoading: () => dispatch(commonActions.startLoading()),
    stopLoading: () => dispatch(commonActions.stopLoading()),
    setInitialSetupStep: (step: InitialSetupStep) => dispatch(initialSetupActions.setStep(step)),
  };
};

export interface IStoragePoolProps extends 
    ReturnType<typeof mapDispatchToProps> {
}

export const StoragePool = connect(null, mapDispatchToProps)((props: IStoragePoolProps) => {
  const [api] = useApi();
  const form: IStoragePoolForm = {
    name: useField<string>("", [validators.required("Storage Pool Name is required")]),
    storagePoolNodes: useField<IStoragePoolNode[]>([], [validateStoragePoolNodes]),
    contentSecurityLevel: useField<number>(CONFIG.minSecurityLevel, []),
    keySecurityLevel: useField<number>(CONFIG.minSecurityLevel, []),
    metaSecurityLevel: useField<number>(CONFIG.minSecurityLevel, []),
  };

  function handleCreateStoragePool(createRequest: IStoragePoolCreateRequest) {
    props.startLoading();
    return api(storagePoolService.addStoragePool(createRequest), null, props.stopLoading, props.stopLoading).then((pool) => {
      props.setInitialSetupStep(InitialSetupStep.StorageProfile);
      return pool;
    });
  }
  
  useEffect(() => {
    props.startLoading();
    api(storagePoolService.getPage({ offset: 0, pageSize: 0}), null, props.stopLoading, props.stopLoading).then((pools) => {
      if (!(pools && pools.count)) {
        props.startLoading();
        api(storageNodeService.getStorageNodes(), null, props.stopLoading, props.stopLoading).then((nodesPage) => {
          if (nodesPage.count) {
            form.storagePoolNodes.onChange(nodesPage.data.map(node => ({ 
              node, 
              types: { 
                storeContent: false, 
                storeKey: false, 
                storeMetadata: false 
              } 
            })));
          }
        });
      }
    });

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <PageTitle title="Setup Storage Pool"/>
      <StoragePoolForm
          mode={StoragePoolFormMode.Create}
          form={form}
          onCreateStoragePool={handleCreateStoragePool}
        />
    </>
  );
});
